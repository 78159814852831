import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './announcements.css';

const Announcements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { user } = useSelector((state) => state.auth);

  const fetchAnnouncements = () => {
    const token = localStorage.getItem('CapacitorStorage.token');
    axios
      .get('https://fastapi-backend-657266059621.asia-northeast1.run.app/api/announcements/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAnnouncements(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(
          err?.response?.data?.detail || 'Failed to load announcements.'
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const handleDeleteAnnouncement = (announcementId) => {
    const token = localStorage.getItem('CapacitorStorage.token');
  
    if (!token || !token.includes('.')) {
      alert('Invalid or missing token. Please log in again.');
      return;
    }
  
    axios
      .delete(`https://fastapi-backend-657266059621.asia-northeast1.run.app/api/announcements/${announcementId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        fetchAnnouncements();  // Refresh list after deletion
      })
      .catch((err) => {
        console.error('Delete failed:', err);
        alert('Failed to delete announcement.');
      });
  };
  

  if (loading) {
    return <p className="loading-text">Loading announcements...</p>;
  }

  if (error) {
    return <p className="error-text">Error: {error}</p>;
  }

  if (announcements.length === 0) {
    return <p className="loading-text">No announcements available.</p>;
  }

  const sortedAnnouncements = [...announcements].sort((a, b) => {
    if (a.pinned && !b.pinned) return -1;
    if (!a.pinned && b.pinned) return 1;
    return 0;
  });

  return (
    <div className="announcements-container">
      <h2 className="announcements-title">공지사항</h2>
      {sortedAnnouncements.map((ann) => (
        <div key={ann.id} className="announcement-item">
          <h3 className="announcement-title">
            {ann.title}
            {ann.pinned && <span className="pinned-badge">📌 Pinned</span>}
          </h3>

          {ann.created_at && (
            <p className="announcement-date">
              Posted: {new Date(ann.created_at).toISOString().split('T')[0]}
            </p>
          )}

          <p className="announcement-content">{ann.content}</p>

          {/* Delete button, visible ONLY for admin (user id === 23) */}
          {user?.id === 23 && (
            <button
              className="delete-announcement-button"
              onClick={() => handleDeleteAnnouncement(ann.id)}
            >
              Delete
            </button>
          )}
        </div>
      ))}
    </div>
  );
};

export default Announcements;
