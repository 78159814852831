// authService.js
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { generateCodeVerifier, generateCodeChallenge } from './pkceUtils';

// Example placeholders for your environment variables or config
const IOS_CLIENT_ID = '657266059621-9khh7nolhotok3la8uc3u8a9bc2hi7rr.apps.googleusercontent.com';
const IOS_REVERSED_SCHEME = 'com.googleusercontent.apps.657266059621-9khh7nolhotok3la8uc3u8a9bc2hi7rr:/oauth';
const WEB_CLIENT_ID = '657266059621-j2i4vfpk8cq9hev35nmi8ka3jgr4linu.apps.googleusercontent.com';
const WEB_REDIRECT_URI = 'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/callback/google';

const KAKAO_NATIVE_APP_KEY = process.env.REACT_APP_KAKAO_NATIVE_APP_KEY;
const KAKAO_REST_API_KEY = process.env.REACT_APP_KAKAO_REST_API_KEY;
const KAKAO_APP_REDIRECT_URI = `kakao${KAKAO_NATIVE_APP_KEY}://oauth`;
const KAKAO_WEB_REDIRECT_URI = process.env.REACT_APP_KAKAO_WEB_REDIRECT_URI;

export async function signInWithGoogle() {
  const platform = Capacitor.getPlatform();
  let clientId, redirectUri;

  if (platform === 'ios') {
    clientId = IOS_CLIENT_ID;
    redirectUri = IOS_REVERSED_SCHEME;
  } else {
    clientId = WEB_CLIENT_ID;
    redirectUri = WEB_REDIRECT_URI;
  }

  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  localStorage.setItem('google_code_verifier', codeVerifier);

  const params = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: 'openid profile email',
    access_type: 'offline',
    prompt: 'consent',
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
  });

  const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;

  if (platform === 'web') {
    window.location.href = authUrl;
  } else {
    await Browser.open({ url: authUrl });
  }
}

export async function signInWithKakao() {
  const platform = Capacitor.getPlatform();
  let clientId, redirectUri;

  if (platform === 'ios' || platform === 'android') {
    clientId = KAKAO_NATIVE_APP_KEY;
    redirectUri = KAKAO_APP_REDIRECT_URI;
  } else {
    clientId = KAKAO_REST_API_KEY;
    redirectUri = KAKAO_WEB_REDIRECT_URI;
  }

  const params = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
  });

  const authUrl = `https://kauth.kakao.com/oauth/authorize?${params.toString()}`;

  if (platform === 'web') {
    window.location.href = authUrl;
  } else {
    await Browser.open({ url: authUrl });
  }
}

export async function signInWithApple() {
  const platform = Capacitor.getPlatform();

  if (platform === 'ios') {
    try {
      // Native Apple sign in
      const appleCredential = await SignInWithApple.authorize();
      console.log('🍎 Apple Credential:', appleCredential);

      const code = appleCredential?.response?.authorizationCode;
      if (!code) {
        console.error('⚠️ Apple 로그인 실패: authorizationCode 없음');
        return null;
      }

      // Attempt to get the user's full name (only available first time)
      let userFullName = '';
      const appleFullName = appleCredential?.response?.fullName;
      if (appleFullName) {
        const { givenName = '', familyName = '' } = appleFullName;
        userFullName = `${givenName} ${familyName}`.trim();
      }

      // Exchange code for token with FastAPI
      const response = await fetch(
        'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/callback/apple/mobile',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code, fullName: userFullName }),
        }
      );
      const data = await response.json();
      console.log('✅ Apple 백엔드 응답:', data);

      return data;
    } catch (err) {
      console.error('❌ Apple 로그인 에러:', err);
      return null;
    }
  } else {
    // Fallback for Android or Web
    const APPLE_WEB_CLIENT_ID = process.env.REACT_APP_APPLE_WEB_CLIENT_ID;
    const APPLE_WEB_REDIRECT_URI = process.env.REACT_APP_APPLE_WEB_REDIRECT_URI;

    const params = new URLSearchParams({
      client_id: APPLE_WEB_CLIENT_ID,
      redirect_uri: APPLE_WEB_REDIRECT_URI,
      response_type: 'code',
      scope: 'name email',
      state: 'random_generated_state',
      response_mode: 'form_post',
    });

    const authUrl = `https://appleid.apple.com/auth/authorize?${params.toString()}`;
    window.location.href = authUrl;
    return null;
  }
}

export async function signInWithSpotify() {
  const platform = Capacitor.getPlatform();
  let clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
  let redirectUri = process.env.REACT_APP_SPOTIFY_WEB_REDIRECT_URI;

  if (platform === 'ios' || platform === 'android') {
    redirectUri = process.env.REACT_APP_SPOTIFY_MOBILE_REDIRECT_URI;
  }

  const params = new URLSearchParams({
    client_id: clientId,
    response_type: 'code',
    redirect_uri: redirectUri,
    scope: 'user-read-private user-read-email',
  });

  const authUrl = `https://accounts.spotify.com/authorize?${params.toString()}`;

  if (platform === 'web') {
    window.location.href = authUrl;
  } else {
    await Browser.open({ url: authUrl });
  }
}
