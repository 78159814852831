// MyPage.jsx
import React from 'react';
import './MyPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCircle,
  faSignOutAlt,
  faUserEdit,
  faHeadphones,
  faBullhorn,
  faBell,
  faTimes,  // 닫기 아이콘 추가
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, setGuestLogin } from '../../store/slices/authSlice';

const MyPage = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isAuthenticated, isGuest, isLoading } = useSelector(
    (state) => state.auth
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated && !isGuest) {
    return null;
  }

  const handleLogout = () => {
    dispatch(logoutUser())
      .then(() => {
        setTimeout(() => {
          dispatch(
            setGuestLogin({
              id: 'guest',
              name: 'Guest',
              email: 'guest@example.com',
              isGuest: true,
            })
          );
          navigate('/home');
          window.location.reload();
        }, 100);
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  const handleEditInfoClick = () => {
    navigate('/edit-info');
  };

  const handleNotificationSettingsClick = () => {
    navigate('/notification-settings');
  };

  const handleCustomerServiceClick = () => {
    navigate('/customer-service');
  };

  const handleAnnouncementsClick = () => {
    navigate('/announcements');
  };
  
  // NEW: admin-only route to create announcements
  const handleCreateAnnouncementClick = () => {
    navigate('/admin-announcements');
  };

  return (
    <div className="my-page">
      {/* [New] 닫기 버튼 */}
      {onClose && (
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}

      <div className="profile-section">
        <FontAwesomeIcon icon={faUserCircle} className="profile-icon" />
        <h2>{user?.name || '게스트 사용자'}</h2>
        <p>{user?.email || 'guest@example.com'}</p>
      </div>

      <div className="settings-section">
        <h3>Settings</h3>
        <ul>
          <li onClick={handleEditInfoClick}>
            <FontAwesomeIcon icon={faUserEdit} style={{ marginRight: '8px' }} />
            회원정보 수정
          </li>
          {/*}
          <li onClick={handleNotificationSettingsClick}>
            <FontAwesomeIcon icon={faBell} style={{ marginRight: '8px' }} />
            알림 설정
          </li> */}
          <li onClick={handleCustomerServiceClick}>
            <FontAwesomeIcon icon={faHeadphones} style={{ marginRight: '8px' }} />
            고객센터
          </li>
          <li onClick={handleAnnouncementsClick}>
            <FontAwesomeIcon icon={faBullhorn} style={{ marginRight: '8px' }} />
            공지사항
          </li>

          {/* Only show if user.id === 23 (our "admin") */}
          {user?.id === 23 && (
            <li onClick={handleCreateAnnouncementClick}>
              <FontAwesomeIcon icon={faBullhorn} style={{ marginRight: '8px' }} />
              공지사항 등록 (Admin)
            </li>
          )}
        </ul>
      </div>

      {!isGuest ? (
        <button className="logout-button" onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
          Logout
        </button>
      ) : (
        <button className="logout-button" onClick={handleLoginRedirect}>
          <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
          Login
        </button>
      )}
    </div>
  );
};

export default MyPage;
