import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './TicketPurchasePage.css';
import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';


const TicketPurchasePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const clubName = location?.state?.clubName || '클럽 이름 없음';
  const clubId = location?.state?.clubId; // ✅ 클럽 ID 가져오기
  const ticketPrices = location?.state?.ticketPrices || { weekday: 15000, weekend: 20000 }; // ✅ 기본 가격 설정

  const [quantity, setQuantity] = useState(1);
  const [selectedDayGroup, setSelectedDayGroup] = useState(""); // ✅ 선택한 요일 그룹 저장
  const [ticketPrice, setTicketPrice] = useState(0); // ✅ 선택한 요일 그룹에 따라 가격 업데이트
  const totalPrice = ticketPrice * quantity;

  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [paymentWidget, setPaymentWidget] = useState(null);
  const paymentMethodsWidgetRef = useRef(null);
  const [isPaymentActive, setIsPaymentActive] = useState(false); // ✅ 추가: 결제 UI 활성화 상태
  const [user, setUser] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  
  const showNotificationPopup = (message) => {
    setNotificationText(message);
  };


  const fetchUser = async () => {
    try {
      const token = localStorage.getItem("CapacitorStorage.token");
      console.log("저장된 토큰:", token);  // ✅ 토큰 확인

      const response = await fetch("https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/user", {
        method: "GET",  
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, 
        },
      });
  
      if (!response.ok) {
        throw new Error("유저 정보를 가져오지 못했습니다.");
      }
  
      return await response.json();
    } catch (error) {
      console.error("🚨 유저 정보 요청 실패:", error);
      return null;
    }
  };
  
  useEffect(() => {
    const loadUser = async () => {
      const userData = await fetchUser();
      setUser(userData);
    };
  
    loadUser();
  }, []);

  useEffect(() => {
    if (selectedDayGroup) {
      setTicketPrice(ticketPrices[selectedDayGroup] || 0);
    }
  }, [selectedDayGroup, ticketPrices]);

  

  useEffect(() => {
    if (!isPaymentActive) return; // ✅ 결제 UI가 활성화될 때만 위젯 로드

    const initWidget = async () => {
      try {
        const clientKey = 'test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm';
        const customerKey = `user_${Math.random().toString(36).substring(2, 15)}`;

        const loadedWidget = await loadPaymentWidget(clientKey, customerKey);

        setPaymentWidget(loadedWidget);
      } catch (error) {
        console.error('🚨 위젯 초기화 실패:', error);
      }
    };

    initWidget();
  }, [isPaymentActive]); // ✅ isPaymentActive가 변경될 때만 실행
  useEffect(() => {
    if (selectedDayGroup) {
      const newPrice = ticketPrices[selectedDayGroup] ?? 15000; // ✅ 기본값 설정
      setTicketPrice(newPrice);
      console.log(`📢 ${selectedDayGroup} 가격 업데이트:`, newPrice);
    }
  }, [selectedDayGroup, ticketPrices]);

  useEffect(() => {
    if (!isPaymentActive || !paymentWidget) return; // ✅ 결제 UI 활성화 시 위젯 렌더링

    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      "#payment-method",
      { value: totalPrice },
      { variantKey: "DEFAULT" }
    );

    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

    paymentMethodsWidgetRef.current = paymentMethodsWidget;
  }, [isPaymentActive, paymentWidget, totalPrice]);

  useEffect(() => {
    if (!isPaymentActive || !paymentMethodsWidgetRef.current) return;
    paymentMethodsWidgetRef.current.updateAmount(totalPrice);
  }, [isPaymentActive, totalPrice]);

  const handleIncrease = () => setQuantity(quantity + 1);
  const handleDecrease = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };
  const handlePaymentRequest = async () => {
    // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
    if (!user) {
      //alert("로그인 후 이용 가능합니다.");
      showNotificationPopup("로그인 후 이용 가능합니다.");
      return;
    }
    try {

      
      await paymentWidget?.requestPayment({
      orderId: `order_${new Date().getTime()}`,
      orderName: `${clubName} 프리패스 입장권 ${quantity}매`,
      customerName: user.username,
      customerEmail: user.email,
      customerMobilePhone: user.phone,
      successUrl: `${window.location.origin}/success`,
      failUrl: `${window.location.origin}/fail`,
      });
      
    } catch (error) {
      console.error("Error requesting payment:", error);
    }
  };

  const handleDaySelection = (e) => {
    const selectedValue = e.target.value;
    setSelectedDayGroup(selectedValue);
  
    // ✅ ticketPrices[selectedValue]가 undefined이면 기본값(15000)으로 설정
    const newTicketPrice = ticketPrices[selectedValue] ?? 15000; // `??` 연산자로 기본값 설정
    setTicketPrice(newTicketPrice);
    localStorage.setItem("selectedDayGroup", selectedValue);

  
    console.log("✅ 선택된 요일 그룹:", selectedValue);
    console.log("🛠 ticketPrices[selectedValue]:", ticketPrices[selectedValue]);
  };
  
  
  
  

  const isPurchaseEnabled = agreePrivacy && agreeTerms;

  const handlePurchase = async () => {
    // 티켓 불가능 처리
    
    if (!user) {
      //alert("로그인 후 이용 가능합니다.");
      showNotificationPopup("로그인 후 이용 가능합니다.");
      return;
    }
  
    if (!isPurchaseEnabled) return;

  
    if (!clubId) {
      //alert("클럽 정보가 없습니다.");
      showNotificationPopup("클럽 정보가 없습니다.");
      return;
    }
  
    // ✅ 유저가 선택한 요일 그룹 저장
    const selectedDayGroup = document.querySelector('input[name="dayGroup"]:checked')?.value;
    if (!selectedDayGroup) {
      //alert("요일을 선택하세요.");
      showNotificationPopup("요일을 선택하세요.");
      return;
    }
  
    localStorage.setItem("selectedClubId", clubId);
    localStorage.setItem("selectedDayGroup", selectedDayGroup);
    

    setIsPaymentActive(true); // ✅ 결제 UI 활성화
  };

  return (
    <div className="ticket-purchase-page">
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}
      {/* ✅ 기존 화면 숨김 처리 */}
      <div className={`main-content ${isPaymentActive ? "hidden" : ""}`}>
        <span className="popup-back-symbol" onClick={() => navigate(-1)}>
          &lt;
        </span>

        <h1 className="club-name">{clubName} 프리패스 입장권</h1>

        <div className="section-container">
          <p className="info-title">입장권 이용 가능 시간</p>
          <p className="info-detail">23:00 ~ 01:00</p>
        </div>

        <div className="section-container">
          <p className="info-title">입장권 유효기간</p>
          <p className="info-detail">해당 입장권은 7일간 이용 가능합니다.</p>
        </div>

        <div className="section-container">
          <p className="info-title">입장권 가격</p>
          <div className="day-selection">
    <label>
      <input type="radio" name="dayGroup" value="weekday" onChange={handleDaySelection} />
      주중 (월~목, 일)
    </label>
    <label>
      <input type="radio" name="dayGroup" value="weekend" onChange={handleDaySelection} />
      주말 (금~토)
    </label>
  </div>
          <div className="quantity-control">
            <button className="quantity-button" onClick={handleDecrease}>-</button>
            <span className="quantity-display">{ quantity }</span>
            <button className="quantity-button" onClick={handleIncrease}>+</button>
          </div>
          <p className="price">{ticketPrice.toLocaleString()}원</p>
        </div>

        <div className="section-container ">
          <p className="payment-title">결제상세</p>
          <div className="payment-row">
            <span className="payment-label">이용금액</span>
            <span className="payment-value">{totalPrice.toLocaleString()}원</span>
          </div>
          <div className="payment-row total">
            <span className="payment-label">최종 결제금액</span>
            <span className="payment-value">{totalPrice.toLocaleString()}원</span>
          </div>
        </div>

        <div className="section-container agreement-section">
          <p className="agreement-title">취소 및 부도위약금 안내</p>
          <p className="agreement-text">위 구매조건을 모두 확인하였으며, 결제에 동의합니다.</p>
          <label className="checkbox-label">
            <input type="checkbox" checked={agreePrivacy} onChange={() => setAgreePrivacy(!agreePrivacy)} />
            개인정보 수집 동의(필수)
          </label>
          <label className="checkbox-label">
            <input type="checkbox" checked={agreeTerms} onChange={() => setAgreeTerms(!agreeTerms)} />
            전자금융거래 이용약관 동의(필수)
          </label>
        </div>

        <button className={`purchase-button ${isPurchaseEnabled ? "active" : ""}`} onClick={handlePurchase} disabled={!isPurchaseEnabled}>
          결제하기
        </button>
      </div>

      {/* ✅ 결제 UI (기존 화면 숨기고 토스페이만 보이도록) */}
      {isPaymentActive && (
  <div className="payment-overlay">
    <div className="payment-container">
    <div id="payment-method"></div>
    <div id="agreement"></div>
    <button className="purchase-button active" onClick={handlePurchase} disabled={true}>
  결제하기
</button>
      <button className="cancel-button" onClick={() => setIsPaymentActive(false)}>취소</button>
      
    </div>
  </div>
)}
    </div>
  );
};

export default TicketPurchasePage;
