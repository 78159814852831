import React, { useState, useEffect } from 'react';
import './LoginPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faApple, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithToken, setGuestLogin, restoreAuthState } from '../../store/slices/authSlice';
import axios from 'axios';
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';
import { Storage } from '@capacitor/storage';

// Import your social sign-in helpers
import { signInWithGoogle, signInWithKakao, signInWithApple, signInWithSpotify } from '../../services/authService';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState('');
  const [notificationText, setNotificationText] = useState("");

  useEffect(() => {
    dispatch(restoreAuthState());
  }, [dispatch]);

  const showNotificationPopup = (message) => {
    setNotificationText(message);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const errMessage = params.get('error');
    if (errMessage) {
      showNotificationPopup(decodeURIComponent(errMessage));
      params.delete('error');
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  const handleLogin = async (service) => {
    if (service === 'google') {
      await signInWithGoogle();
    } else if (service === 'kakao') {
      await signInWithKakao();
    } else if (service === 'spotify') {
      await signInWithSpotify();
    } else if (service === 'apple') {
      const data = await signInWithApple();
      if (data && data.access_token) {
        dispatch(loginWithToken(data.access_token))
          .unwrap()
          .then(() => {
            navigate('/home');
          })
          .catch(() => {
            navigate('/mypage');
          });
      }
      return;
    } else {
      window.location.href = `https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/login/${service}`;
    }

    // 소셜 로그인 후 일정 시간(2초) 후 Storage에 저장된 토큰을 확인하여 상태 복원
    setTimeout(async () => {
      const { value: token } = await Storage.get({ key: 'token' });
      if (token) {
        dispatch(loginWithToken(token))
          .unwrap()
          .then((res) => {
            if (!res.user.phone) {
              //navigate('/phone-verification');
              navigate('/home');
            } else {
              navigate('/home');
            }
          })
          .catch(() => navigate('/mypage'));
      }
    }, 2000);
  };

  const handleLaterClick = () => {
    dispatch(
      setGuestLogin({
        id: 'guest',
        name: 'Guest',
        email: 'guest@example.com',
        isGuest: true,
      })
    );
    navigate('/home');
  };

  const handleSignupClick = () => {
    navigate('/signup');
  };

  const handleFindPw = () => {
    navigate('/find-pw');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError('');

    try {
      const response = await axios.post(
        'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/login',
        { email, password },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const { access_token } = response.data;

      dispatch(loginWithToken(access_token))
        .unwrap()
        .then(() => {
          navigate('/home');
        })
        .catch((err) => {
          setFormError(err || '로그인 중 오류가 발생했습니다.');
        });
    } catch (error) {
      const detail = error.response?.data?.detail;
      if (detail) {
        setFormError(detail);
      } else {
        setFormError('로그인 중 오류가 발생했습니다. 다시 시도해주세요.');
      }
    }
  };

  return (
    <div className="login-page">
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}
      <div className="logo-container">
        <h1 className="logo-text">H2P</h1>
        <h2 className="logo-subtitle">HOW2PLAY</h2>
      </div>

      <div className="title-container">
        <p className="login-subtitle">당신의 취향에 맞는 클럽을 찾아가세요!</p>
      </div>

      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="이메일"
          className="input-field"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="비밀번호"
          className="input-field"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {formError && <p className="error-message">{formError}</p>}
        {error && <p className="error-message">{error}</p>}

        <button className="login-submit-btn" type="submit" disabled={isLoading}>
          {isLoading ? 
            <div className="loading-spinner-container">
              <img
                src="/How2Play_mainLogo128.png"
                alt="Loading"
                className="logo-spin"
              />
            </div>
          : '로그인'}
        </button>
      </form>

      <div className="find-info">
        <span onClick={handleSignupClick} className="find-id-link">
          회원가입
        </span>
        {' '}|{' '}
        <span onClick={handleFindPw} className="find-id-link">
          비밀번호 찾기
        </span>
      </div>

      <div className="login-buttons">
        <button className="login-btn google" onClick={() => handleLogin('google')}>
          <FontAwesomeIcon icon={faGoogle} className="icon" /> Google 계속하기
        </button>
        <button className="login-btn kakao" onClick={() => handleLogin('kakao')}>
          <FontAwesomeIcon icon={faComment} className="icon" /> 카카오 계속하기
        </button>
        <button className="login-btn spotify" onClick={() => handleLogin('spotify')}>
          <FontAwesomeIcon icon={faSpotify} className="icon" /> Spotify 계속하기
        </button>
        <button className="login-btn apple" onClick={() => handleLogin('apple')}>
          <FontAwesomeIcon icon={faApple} className="icon" /> Apple 계속하기
        </button>
      </div>

      <div className="later-link">
        <span onClick={handleLaterClick}>나중에 하기</span>
      </div>
    </div>
  );
};

export default LoginPage;
