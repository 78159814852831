import React from 'react';
import { useNavigate } from 'react-router-dom';

import './TermsOfService.css';

const TermsOfServicePage = ({ onClose = null }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="terms-page">
      <button className="close-button" onClick={handleClose}>
        &times;
      </button>
      <h1 className="terms-title">서비스 이용약관</h1>

      <section className="terms-section">
        <h2>제1조 (목적)</h2>
        <p>
          이 약관은 장이신(이하 “회사”)이 제공하는 클럽 관련 모바일 애플리케이션 및 제반 서비스(이하 “서비스”)의 이용과 관련하여, 회사와 이용자 간의 권리, 의무 및 책임사항을 정함을 목적으로 합니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제2조 (용어의 정의)</h2>
        <p>
          1. <strong>회사</strong>: 클럽 정보 제공 및 예약 등 서비스를 운영하는 장이신.<br />
          2. <strong>이용자</strong>: 본 약관에 따라 회사가 제공하는 서비스를 이용하는 자(회원 및 비회원 포함).<br />
          3. <strong>회원</strong>: 회사의 서비스에 접속하여 약관에 동의하고 회원가입을 완료한 자.<br />
          4. <strong>서비스</strong>: 모바일 애플리케이션 등을 통해 회사가 제공하는 클럽 정보, 근처 클럽 추천, 클럽 행사 티켓 예매 등 일체의 서비스.<br />
          5. <strong>유료서비스</strong>: 이용자가 결제를 통해 구매하는 상품 또는 권리(예: 클럽 행사 입장권, 예약권 등).<br />
          6. <strong>개인위치정보</strong>: 이용자의 휴대전화 GPS 정보 등 위치를 식별할 수 있는 정보.
        </p>
      </section>

      <section className="terms-section">
        <h2>제3조 (약관의 명시와 개정)</h2>
        <p>
          1. 회사는 본 약관의 내용을 이용자가 쉽게 알 수 있도록 서비스 초기 화면 또는 연결화면 등에 게시합니다.<br />
          2. 회사는 관련 법령에 위배되지 않는 범위에서 약관을 개정할 수 있으며, 개정 시 적용일자 및 개정사유를 사전 공지합니다.<br />
          3. 이용자가 개정 약관에 동의하지 않을 경우 서비스 이용을 중단할 수 있으며, 개정 약관 시행 후에도 계속 이용하면 동의한 것으로 간주합니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제4조 (회원가입 및 계정 관리)</h2>
        <p>
          1. 회원가입은 회사가 요청하는 필수 정보를 정확하게 제공하고, 본 약관 및 개인정보처리방침에 동의한 후 승인을 통해 이루어집니다.<br />
          2. 회사는 부정한 정보 제공, 타인 명의 도용 등 부정 행위가 확인되면 회원가입 신청을 거부하거나 회원자격을 상실시킬 수 있습니다.<br />
          3. 회원은 가입 시 등록한 정보에 변동이 있을 경우 즉시 수정하여야 하며, 정보 관리 부주의로 인한 불이익은 회원의 책임입니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제5조 (서비스 이용 및 변경)</h2>
        <p>
          1. 회사는 회원에게 서비스 이용에 필요한 계정 및 기타 정보를 제공하며, 서비스의 내용은 회사의 정책에 따라 변경·추가될 수 있습니다.<br />
          2. 서비스 변경 또는 중단 시 회사는 사전에 공지할 수 있으며, 불가피한 경우 사후에 통지할 수 있습니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제6조 (요금 및 결제)</h2>
        <p>
          1. <strong>유료서비스 요금:</strong> 유료서비스 이용을 위해 명시된 가격을 결제해야 하며, 가격 및 이용 기간은 서비스 화면에 안내됩니다. 기존 결제 건은 변경 전 조건이 적용됩니다.<br /><br />
          2. <strong>결제 방식:</strong> 결제는 회사가 지정한 결제수단인 토스페이(Toss Pay)를 통해서만 진행됩니다. 이용자는 토스페이 이용약관 등 관련 정책을 준수해야 합니다.<br /><br />
          3. <strong>결제 완료:</strong> 이용자가 결제 절차를 완료하면 회사 또는 결제 대행사가 결제 성공 여부를 확인한 후 유료서비스를 제공합니다.<br /><br />
          4. <strong>미성년자의 결제:</strong> 만 19세 미만의 미성년 이용자가 결제 시 법정대리인의 동의를 받아야 하며, 동의 없이 진행된 결제는 취소될 수 있습니다.<br /><br />
          5. <strong>전자금융거래 이행:</strong> 회사는 토스페이를 통한 안전한 결제 처리를 위해 필요한 조치를 취하며, 부정사용이 의심될 경우 결제를 보류하거나 취소할 수 있습니다.<br /><br />
          6. <strong>부가세 및 영수증:</strong> 별도 안내가 없는 한, 요금에 부가세 등이 포함되며, 요청 시 관련 내역의 영수증 또는 계산서를 발급합니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제7조 (취소 및 환불)</h2>
        <p>
          1. <strong>청약철회 기간:</strong> 이용자는 결제 완료 후 7일 이내에 청약철회(취소)를 요청할 수 있습니다. 단, 이용이 개시되었거나 법령에 따라 청약철회가 제한되는 경우는 제외됩니다.<br /><br />
          2. <strong>청약철회 처리:</strong> 결제 완료 후 7일 이내에 청약철회를 요청한 경우, 회사는 기지급 금액을 전액 환불하며, 별도의 수수료는 공제되지 않습니다.<br /><br />
          3. <strong>환불 제한:</strong> 결제일로부터 7일이 경과하면 환불이 원칙적으로 불가능하며, 이용 당일에는 취소 및 환불이 불가합니다.<br /><br />
          4. <strong>부분 환불 예외:</strong> 이용 예정일 하루 전까지 취소 요청 시 결제금액의 50%를 환불합니다. 이 규정은 결제 후 7일이 경과한 경우에도 적용됩니다.<br /><br />
          5. <strong>환불 절차:</strong> 환불 승인 후 3영업일 이내에 환불이 진행되며, 토스페이 정책에 따라 환불 금액이 반환됩니다.<br /><br />
          6. <strong>환불 불가 사유:</strong> 이용자가 유료서비스를 이미 사용했거나, 회사 정책에 위배되는 경우 환불이 제한될 수 있습니다.<br /><br />
          7. <strong>회사 사정에 의한 취소:</strong> 회사 또는 제휴 클럽 주최 측 사유로 서비스 제공이 불가능할 경우, 회사는 결제 금액 전액을 환불하며, 이 경우 환불에 따른 비용은 회사가 부담합니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제8조 (위치기반 서비스 이용 및 개인정보 보호)</h2>
        <p>
          1. 회사는 이용자의 동의를 받아 GPS 정보를 포함한 개인위치정보를 수집·이용하여, 근처 클럽 추천 및 맞춤형 콘텐츠 제공 등 위치기반 서비스를 제공합니다.<br /><br />
          2. 이용자는 앱 설정 등을 통해 위치정보 수집·이용에 대한 동의를 철회할 수 있으며, 동의 철회 시 관련 서비스 제공이 중단됩니다.<br /><br />
          3. 회사는 관련 법령을 준수하여 이용자의 개인위치정보를 보호하며, 동의 없이 제3자에게 제공하지 않습니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제9조 (개인정보 보호)</h2>
        <p>
          1. 회사는 서비스 제공에 필요한 최소한의 개인정보를 수집하며, 수집된 개인정보는 「개인정보 보호법」 및 회사의 개인정보처리방침에 따라 안전하게 관리됩니다.<br /><br />
          2. 이용자는 자신의 개인정보 열람, 정정·삭제, 처리 정지 및 동의 철회를 요청할 수 있으며, 회사는 관련 요청을 신속하게 처리합니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제10조 (회사의 의무)</h2>
        <p>
          1. 회사는 관련 법령 및 본 약관에 따라 안정적이고 성실하게 서비스를 제공하기 위해 최선을 다합니다.<br /><br />
          2. 회사는 개인정보 보호를 위한 보안 시스템을 갖추고, 이용자의 불만이나 문의 사항에 대해 신속하게 대응합니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제11조 (이용자의 의무)</h2>
        <p>
          1. 이용자는 본 약관 및 관련 법령을 준수하며, 회사의 정상적인 서비스 운영을 방해하는 행위를 해서는 안 됩니다.<br /><br />
          2. 이용자가 본 약관을 위반하여 회사에 손해가 발생한 경우, 그 손해에 대해 배상할 책임이 있습니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제12조 (콘텐츠의 저작권 및 사용권)</h2>
        <p>
          1. 서비스 내 콘텐츠의 저작권은 해당 콘텐츠의 창작자에게 있으며, 회사는 서비스 운영 및 홍보 목적을 위해 합리적인 범위 내에서 콘텐츠를 사용할 수 있습니다.<br /><br />
          2. 이용자가 작성한 게시물 등은 해당 이용자에게 귀속되며, 회사는 비독점적 사용권을 보유합니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제13조 (서비스 이용제한 및 계약 해지)</h2>
        <p>
          1. 회사는 이용자가 본 약관을 위반할 경우, 사전 또는 사후 통지를 통해 서비스 이용을 제한하거나 회원자격을 박탈할 수 있습니다.<br /><br />
          2. 회원탈퇴 시 관련 법령에 따라 필요한 정보를 제외한 모든 회원 정보는 삭제됩니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제14조 (면책 조항)</h2>
        <p>
          1. 회사는 천재지변, 전쟁, 통신 장애 등 불가항력적 사유로 인한 서비스 중단에 대해 책임을 지지 않습니다.<br /><br />
          2. 회사는 회원의 귀책 사유로 인한 손해에 대해 책임을 지지 않습니다.<br /><br />
          3. 회사는 제3자 콘텐츠 및 거래와 관련한 손해에 대해 책임을 부담하지 않습니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제15조 (분쟁 해결 및 관할)</h2>
        <p>
          1. 회사와 이용자 간 분쟁은 우선 협의를 통해 해결하며, 협의가 어려울 경우 관련 법령에 따라 분쟁 조정 및 소송 절차를 따릅니다.<br /><br />
          2. 본 약관과 관련된 모든 분쟁에는 대한민국 법령이 적용되며, 관할 법원은 회사의 본사 소재지를 기준으로 합니다.
        </p>
      </section>

      <section className="terms-section">
        <h2>제16조 (부칙)</h2>
        <p>
          1. 본 약관은 이용자에게 온라인으로 공지되며, 이용자가 동의함으로써 효력이 발생합니다.<br /><br />
          2. 회사는 필요 시 별도의 위치기반서비스 약관 및 결제/환불 관련 정책을 마련할 수 있으며, 이 경우 본 약관과 상충하는 부분은 관련 법령에 따라 이용자에게 유리한 조건이 우선 적용됩니다.<br /><br />
          3. 본 약관은 2025년 3월 19일부터 시행됩니다. (공고일: 2025년 3월 12일)
        </p>
      </section>
    </div>
  );
};

export default TermsOfServicePage;
