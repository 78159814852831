import { PushNotifications } from "@capacitor/push-notifications";
import axios from "axios";

export const registerPushNotifications = async () => {
  try {
    // 1. 먼저 푸시 토큰 등록 이벤트 리스너 등록
    PushNotifications.addListener("registration", async (token) => {
      console.log("Push token received:", token.value);

      // 백엔드로 토큰 전송
      const userToken = localStorage.getItem("token"); // JWT 토큰
      await axios.post(
        "https://fastapi-backend-657266059621.asia-northeast1.run.app/api/push-tokens/",
        {
          token: token.value,
          platform: "ios", // 필요에 따라 동적으로 변경 가능
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
    });

    // 2. 등록 에러 이벤트 리스너 등록
    PushNotifications.addListener("registrationError", (error) => {
      console.error("Push registration error:", error);
    });

    // 3. 푸시 알림 권한 요청
    const permission = await PushNotifications.requestPermissions();
    if (permission.receive !== "granted") {
      console.warn("Push notification permission denied");
      return;
    }

    // 4. 푸시 알림 등록 (토큰 요청)
    await PushNotifications.register();

  } catch (error) {
    console.error("Error setting up push notifications:", error);
  }
};
