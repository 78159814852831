// LandingPage.jsx
import React, { useState, useRef, useCallback } from 'react';
import * as htmlToImage from 'html-to-image';
import {
  Music2,
  Users,
  Sparkles,
  PartyPopper,
  ArrowRight
} from 'lucide-react';
import './LandingPage.css'; // 별도 CSS 파일을 불러옴

/* ------------------------------------------------------------------
   알림 팝업(모달) 컴포넌트
------------------------------------------------------------------ */
function NotificationPopup({ message, onClose }) {
  return (
    <div className="notification-popup-overlay" onClick={onClose}>
      <div
        className="notification-popup-content"
        onClick={(e) => e.stopPropagation()}
      >
        <p>{message}</p>
        <button onClick={onClose}>닫기</button>
      </div>
    </div>
  );
}

/* ------------------------------------------------------------------
   질문 데이터
------------------------------------------------------------------ */
const questions = [
  // ⚡ 에너지 (4문항)
  {
    category: '⚡ 에너지',
    question: '클럽 입장각 잡자마자 개좋아하는 비트 들리면?',
    options: [
      '입장하자마자 바로 리듬 타면서 분위기 초토화',
      '일단 바부터 찍고 칵테일로 간지 챙기고 슬쩍 들어감'
    ]
  },
  {
    category: '⚡ 에너지',
    question: "갑자기 니 인생곡 튀어나오면?",
    options: [
      '개신나서 강제 떼창 유도함',
      '조용히 귀만 씰룩씰룩 하면서 박자 슬쩍 타줌'
    ]
  },
  {
    category: '⚡ 에너지',
    question: "친구가 갑자기 '너 오늘 텐션 뭐냐ㅋㅋ 집 가기 싫음?' 하면?",
    options: [
      '어차피 인생 한 번인데 각 제대로 잡고 달림',
      '적당히만 놀다가 택시타고 빠질 각 봄'
    ]
  },
  {
    category: '⚡ 에너지',
    question: '클럽에서 놀다가 아는 사람 마주치면?',
    options: [
      "바로 '헐 미쳤다 너 여기 왔냐' 하면서 같이 붙어서 광란의 밤 시작",
      '그냥 적당히 인사만 하고 각자 놀던 곳으로 흩어짐'
    ]
  },
  // 🎧 음악 취향: 외힙 (4문항)
  {
    category: '🎧 음악 취향: 외힙',
    question: '클럽서 듣고 싶은 외힙 아티스트는?',
    options: [
      'Travis Scott, Drake 같은 클럽 찢는 힙합 슈퍼스타',
      'Brent Faiyaz, Daniel Caesar 같은 분위기 죽이는 R&B 아티스트'
    ]
  },
  {
    category: '🎧 음악 취향: 외힙',
    question: 'DJ가 갑자기 틀어줬으면 하는 개띵반은? (대중적 외힙)',
    options: [
      "Travis Scott의 'ASTROWORLD' 같은 유명 앨범",
      "The Weeknd의 'Starboy'처럼 모두가 좋아할 만한 앨범"
    ]
  },
  {
    category: '🎧 음악 취향: 외힙',
    question: '친구한테 클럽에서 추천하고 싶은 ‘숨겨진’ 외힙 앨범은?',
    options: [
      "Don Toliver의 ‘Heaven or Hell’ 같이 은근 매니아 많은 앨범",
      "Amine의 ‘Limbo’처럼 알 사람만 아는 개띵반"
    ]
  },
  {
    category: '🎧 음악 취향: 외힙',
    question: '클럽 DJ한테 진짜 개부탁해서 듣고 싶은 외힙은?',
    options: [
      'Kendrick Lamar, Post Malone처럼 분위기 확실히 뜨는 유명 아티스트',
      'Smino, GoldLink같은 바이브 장인 인디 느낌 아티스트'
    ]
  },
  // 🚀 경험 추구 (4문항)
  {
    category: '🚀 경험 추구',
    question: '인스타에서 신상클럽 완전 뜬다고 난리치면?',
    options: [
      '이번 주 바로 콜때리고 무조건 찍먹 해봄',
      '아는 디제이가 말아주는 늘 먹던 맛'
    ]
  },
  {
    category: '🚀 경험 추구',
    question: "클럽에서 만난 사람이 '우리 2차 가자, 재밌는 곳 있음' 이러면?",
    options: [
      '궁금증 폭발해서 뭔지 일단 따라가고 봄',
      '괜히 피곤할 것 같아서 스리슬쩍 도망각 잡음'
    ]
  },
  {
    category: '🚀 경험 추구',
    question: '클럽 놀다가 갑자기 친구가 사라졌다면?',
    options: [
      '나 혼자라도 개꿀잼 뽑아보자며 일단 놀던 대로 계속 즐김',
      '급현타와서 친구 찾아 삼만리 시작함'
    ]
  },
  {
    category: '🚀 경험 추구',
    question: '이번 주 클럽 어디 갈지 결정할 때 넌?',
    options: [
      '무조건 SNS랑 커뮤 핫플 찾아서 새로운 곳 갈겨봄',
      '항상 가던 곳으로 복붙이 국룰'
    ]
  },
  // 🔥 모험심 (3문항)
  {
    category: '🔥 모험심',
    question: '클럽에서 뜬금없는 즉흥 퍼포먼스 열리면?',
    options: [
      '궁금해서 가까이 가서 신나게 관람모드',
      '적당히 뒤에서 눈치 보면서 슬쩍 구경만 함'
    ]
  },
  {
    category: '🔥 모험심',
    question: '모르는 사람이 갑자기 같이 놀자고 하면?',
    options: [
      '즉흥적으로 걍 즐겁게 호응하고 같이 놈',
      '적당히 어색한 미소로 살짝 피함'
    ]
  },
  {
    category: '🔥 모험심',
    question: '요즘 클럽에서 뜨는 이상한 춤이 유행하면?',
    options: [
      '남들 하는 거 보고 대충 따라하며 즐김',
      '남들 추는 거 쳐다만 보면서 난 내 스타일대로 놈'
    ]
  }
];

/* ------------------------------------------------------------------
   결과 조합 매핑
------------------------------------------------------------------ */
const combinationMap = {
  // 1) F,H,..
  'F,H,E,W': {
    name: 'Lit Surfer 🔥🏄🏻‍♂️',
    desc: '히트곡과 새 경험을 화끈히 즐기는 인싸 서퍼'
  },
  'F,H,E,S': {
    name: 'Lit Groover 🔥🕺🏻',
    desc: '높은 텐션으로 그루브 타며 리듬감 있게 즐기는 바이브러'
  },
  'F,H,A,W': {
    name: 'Lit Swerver 🔥🛹',
    desc: '분위기 갑자기 바꾸는 클럽의 급발진 왕'
  },
  'F,H,A,S': {
    name: 'Lit Cruiser 🔥🚗',
    desc: '신나게 놀지만 여유롭게 쿨한 매력을 유지하는 타입'
  },

  // 2) F,D,..
  'F,D,E,W': {
    name: 'Heat Surfer 💥🏄🏻‍♂️',
    desc: '딥한 음악에도 뜨겁게 파도타듯 즐기는 감성 서퍼'
  },
  'F,D,E,S': {
    name: 'Heat Groover 💥🕺🏻',
    desc: '깊은 음악을 매끄럽게 타며 세련된 무드를 즐기는 감성파'
  },
  'F,D,A,W': {
    name: 'Heat Swerver 💥🛹',
    desc: '조용하다가 갑자기 분위기 터뜨리는 무드 반전의 고수'
  },
  'F,D,A,S': {
    name: 'Heat Cruiser 💥🚗',
    desc: '딥한 바이브를 스타일리쉬하고 여유롭게 즐기는 멋쟁이 타입'
  },

  // 3) B,H,..
  'B,H,E,W': {
    name: 'Night Surfer 🌙🏄🏻‍♂️',
    desc: '차분한데 히트곡 나오면 미치는 밤의 반전 서퍼'
  },
  'B,H,E,S': {
    name: 'Night Groover 🌙🕺🏻',
    desc: '밤의 히트곡에 부드럽게 춤추며 여유있게 리듬타는 스타일'
  },
  'B,H,A,W': {
    name: 'Night Swerver 🌙🛹',
    desc: '밤에 갑자기 분위기 전환하는 매력 터지는 클러버'
  },
  'B,H,A,S': {
    name: 'Night Cruiser 🌙🚗',
    desc: '메인스트림 음악 속에서 조용하고 편안하게 노는 밤의 크루저'
  },

  // 4) B,D,..
  'B,D,E,W': {
    name: 'Lowkey Surfer 🎧🏄🏻‍♂️',
    desc: '은근히 신나면 혼자서 조용히 즐기는 감성 서퍼'
  },
  'B,D,E,S': {
    name: 'Lowkey Groover 🎧🕺🏻',
    desc: '조용히 음악 따라 매끄럽게 리듬타는 감성 클러버'
  },
  'B,D,A,W': {
    name: 'Lowkey Swerver 🎧🛹',
    desc: '조용히 있다가 갑자기 분위기 바꾸는 은근한 반전 매력'
  },
  'B,D,A,S': {
    name: 'Lowkey Cruiser 🎧🚗',
    desc: '딥한 음악과 함께 은근한 여유 즐기는 감성 스타일'
  }
};

const recommendedCombinationMapping = {
  'F,H,E,W': 'Heat Groover ✨🕺',
  'F,H,E,S': 'Night Swerver 🎺🌙',
  'F,H,A,W': 'Lowkey Cruiser 🎧🛹',
  'F,H,A,S': 'Heat Surfer 💥🏄‍♂️',
  'F,D,E,W': 'Night Groover 🌛🕺',
  'F,D,E,S': 'Lowkey Swerver 🎧✨',
  'B,H,E,W': 'Lowkey Groover 🎧🕺',
  'B,H,E,S': 'Lowkey Surfer 🎧🏄‍♂️'
};

/* ------------------------------------------------------------------
   성향 계산 함수
------------------------------------------------------------------ */
function calculatePersonality(answers) {
  const categoryMapping = {};
  questions.forEach((q, idx) => {
    if (!categoryMapping[q.category]) categoryMapping[q.category] = [];
    categoryMapping[q.category].push(answers[idx]); // 'A' 또는 'B'
  });

  const getMajority = (arr) =>
    arr.filter((a) => a === 'A').length >= arr.length / 2 ? 'A' : 'B';

  const energyType = getMajority(categoryMapping['⚡ 에너지']);
  const musicType = getMajority(categoryMapping['🎧 음악 취향: 외힙']);
  const experienceType = getMajority(categoryMapping['🚀 경험 추구']);
  const adventureType = getMajority(categoryMapping['🔥 모험심']);

  const energyLetter = energyType === 'A' ? 'F' : 'B';
  const musicLetter = musicType === 'A' ? 'H' : 'D';
  const experienceLetter = experienceType === 'A' ? 'E' : 'A';
  const adventureLetter = adventureType === 'A' ? 'W' : 'S';

  const finalCode = `${energyLetter},${musicLetter},${experienceLetter},${adventureLetter}`;

  const resultData = combinationMap[finalCode] || {
    name: 'Unique Vibe',
    desc: '당신은 유니크한 파티 바이브를 가진 특별한 인물입니다.'
  };
  const recommendation =
    recommendedCombinationMapping[finalCode] || '추천 조합 정보가 없습니다.';

  return {
    code: finalCode,
    title: resultData.name,
    description: resultData.desc,
    recommendation
  };
}

/* ------------------------------------------------------------------
   메인 컴포넌트: LandingPage
------------------------------------------------------------------ */
function LandingPage() {
  // 테스트 진행 상태
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [result, setResult] = useState(null);

  // 공유용 Ref
  const resultRef = useRef(null);

  // 알림 팝업 상태
  const [notificationText, setNotificationText] = useState('');

  // 사업자 정보 모달
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const openBusinessModal = () => setShowBusinessModal(true);
  const closeBusinessModal = () => setShowBusinessModal(false);

  // 문의하기 모달
  const [showInquiryModal, setShowInquiryModal] = useState(false);
  const [inquiryType, setInquiryType] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [status, setStatus] = useState('');

  // 알림 팝업 띄우기
  const showNotificationPopup = (msg) => {
    setNotificationText(msg);
  };

  // 문의 폼 전송 로직
  const handleSubmitInquiry = async (e) => {
    e.preventDefault();
    if (!agreed) {
      showNotificationPopup('개인정보 처리에 동의하셔야 문의를 전송할 수 있습니다.');
      return;
    }

    setStatus('전송 중...');

    try {
      const response = await fetch(process.env.REACT_APP_FORMSPREE_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inquiryType, email, message })
      });

      if (response.ok) {
        setStatus('문의가 성공적으로 전송되었습니다!');
        setInquiryType('');
        setEmail('');
        setMessage('');
        setAgreed(false);
        setTimeout(() => {
          setShowInquiryModal(false);
          setStatus('');
        }, 1500);
      } else {
        setStatus('문의 전송 중 오류가 발생했습니다.');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('문의 전송 중 오류가 발생했습니다.');
    }
  };

  const closeInquiryModal = () => {
    setShowInquiryModal(false);
    setStatus('');
  };

  // 테스트 시작
  const handleStartTest = () => setIsTestStarted(true);

  // 질문에 답변
  const handleAnswer = (answerIndex) => {
    const newAnswers = [...answers, answerIndex === 0 ? 'A' : 'B'];
    setAnswers(newAnswers);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const personalityResult = calculatePersonality(newAnswers);
      setResult(personalityResult);
      const newUrl = `${window.location.origin}${window.location.pathname}?result=${personalityResult.code}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  };

  // 테스트 리셋
  const resetTest = () => {
    setIsTestStarted(false);
    setCurrentQuestion(0);
    setAnswers([]);
    setResult(null);
    window.history.pushState({}, '', window.location.pathname);
  };

  // 결과 공유
  const copyShareLink = () => {
    navigator.clipboard.writeText(window.location.href);
    alert('결과 공유 링크가 복사되었습니다!');
  };

  const shareCapturedImage = useCallback(async () => {
    if (!resultRef.current) return;
    try {
      const dataUrl = await htmlToImage.toPng(resultRef.current, {
        backgroundColor: '#000',
        canvasWidth: 1080,
        canvasHeight: 1920,
        pixelRatio: 2,
        filter: (node) => !node.classList?.contains('no-capture')
      });
      const response = await fetch(dataUrl);
      const blob = await response.blob();
      const file = new File([blob], 'result.png', { type: blob.type });

      if (navigator.canShare && navigator.canShare({ files: [file] })) {
        await navigator.share({
          files: [file],
          title: '클럽 vibe 결과',
          text: '내 클럽 vibe 결과를 확인해보세요!',
          url: window.location.href
        });
      } else {
        copyShareLink();
      }
    } catch (error) {
      console.error('공유 이미지 캡쳐 오류:', error);
      alert('이미지 공유에 실패했습니다.');
    }
  }, []);

  /* ------------------------------------------------------------------
     렌더링 분기
  ------------------------------------------------------------------ */

  // (1) 결과 페이지 – 푸터 숨김
  if (result) {
    return (
      <div className="landing-container result-page">
        {notificationText && (
          <NotificationPopup
            message={notificationText}
            onClose={() => setNotificationText('')}
          />
        )}

        <div className="result-box" ref={resultRef}>
          <div className="result-header">
            <PartyPopper className="result-icon" />
          </div>
          <h2 className="result-heading">당신의 클럽 성향은?</h2>
          <div className="result-content">
            <p className="result-title">{result.title}</p>
            <p className="result-description">{result.description}</p>
            <p className="result-recommendation">
              추천 조합: {result.recommendation}
            </p>
            <button onClick={shareCapturedImage} className="share-button no-capture">
              결과 공유하기
            </button>
            <button onClick={resetTest} className="share-button no-capture">
              테스트 다시하기
            </button>
            <button 
              onClick={() => window.location.href = "링크를_여기에_넣으세요"} 
              className="share-button no-capture"
            >
              앱으로 가기
            </button>
          </div>
        </div>

        {/* 공유 미리보기 모달 (초기 display:none) */}
        <div
          id="preview-modal"
          style={{
            display: 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1000,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={{ position: 'relative', width: '1080px', maxWidth: '90%' }}>
            <img
              id="preview-image"
              alt="공유 미리보기"
              style={{ width: '100%', height: 'auto' }}
            />
            <button
              onClick={() => {
                const modal = document.getElementById('preview-modal');
                if (modal) modal.style.display = 'none';
              }}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: '#ff3366',
                color: '#fff',
                border: 'none',
                padding: '8px',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    );
  }

  // (2) 테스트 진행 페이지 – 푸터 숨김
  if (isTestStarted) {
    const progressPercent = ((currentQuestion + 1) / questions.length) * 100;
    return (
      <div className="landing-container test-page-container">
        {notificationText && (
          <NotificationPopup
            message={notificationText}
            onClose={() => setNotificationText('')}
          />
        )}

        <div className="test-box">
          <div className="question-progress-container">
            <p className="question-counter">
              Question {currentQuestion + 1} of {questions.length}
            </p>
            <div className="progress-bar-bg">
              <div
                className="progress-bar-fill"
                style={{ width: `${progressPercent}%` }}
              />
            </div>
          </div>
          <h2 className="question-text">
            {questions[currentQuestion].question}
          </h2>
          <div className="options-container">
            {questions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                className="option-button"
                onClick={() => handleAnswer(index)}
              >
                <span>{option}</span>
                <ArrowRight className="arrow-icon" />
              </button>
            ))}
          </div>
        </div>
        {/* 푸터는 테스트 진행 중에는 보이지 않음 */}
      </div>
    );
  }

  // (3) 랜딩 페이지 (테스트 시작 전) – 푸터 보임
  return (
    <div className="landing-container">
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText('')}
        />
      )}

      <div className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">
            당신의 <span className="gradient-text">클럽 vibe</span>을 알아보세요
          </h1>
          <p className="hero-subtitle">
            나의 클럽 스타일을 파악하고 더 즐거운 파티를 즐겨보세요
          </p>
          <button className="start-button" onClick={handleStartTest}>
            테스트 시작하기 <ArrowRight className="arrow-icon" />
          </button>
        </div>
        <div className="hero-image">
          <img
            src="https://images.unsplash.com/photo-1574391884720-bbc3740c59d1?auto=format&fit=crop&q=80&w=800"
            alt="Club atmosphere"
            className="rounded-2xl"
          />
        </div>
      </div>

      <div className="features-section">
        <div className="feature-card">
          <Music2 className="feature-icon" />
          <h3>맞춤형 인사이트</h3>
          <p>당신만의 특별한 파티 스타일을 발견하세요</p>
        </div>
        <div className="feature-card">
          <Users className="feature-icon" />
          <h3>소셜 다이나믹스</h3>
          <p>나이트라이프에서의 당신의 모습을 이해하세요</p>
        </div>
        <div className="feature-card">
          <Sparkles className="feature-icon" />
          <h3>바이브 매칭</h3>
          <p>당신에게 딱 맞는 파티 분위기를 찾아보세요</p>
        </div>
        <div className="feature-card">
          <PartyPopper className="feature-icon" />
          <h3>파티 프로필</h3>
          <p>상세한 나이트라이프 성향 리포트를 받아보세요</p>
        </div>
      </div>

      <div className="testimonials-section">
        <h2 className="section-title">클러버들의 이야기</h2>
        <div className="testimonials-grid">
          <div className="testimonial-card">
            <img
              src="https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?auto=format&fit=crop&q=80&w=200"
              alt="Testimonial 1"
              className="testimonial-image"
            />
            <p>"완전 내 스타일을 정확하게 맞췄어요! 🎉"</p>
            <span className="testimonial-name">Alex, 23</span>
          </div>
          <div className="testimonial-card">
            <img
              src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?auto=format&fit=crop&q=80&w=200"
              alt="Testimonial 2"
              className="testimonial-image"
            />
            <p>"내가 좋아하는 클럽 스타일을 찾았어요! 💃"</p>
            <span className="testimonial-name">Sofia, 25</span>
          </div>
          <div className="testimonial-card">
            <img
              src="https://images.unsplash.com/photo-1522556189639-b150ed9c4330?auto=format&fit=crop&q=80&w=200"
              alt="Testimonial 3"
              className="testimonial-image"
            />
            <p>"너무 정확해서 놀랐어요! 🌟"</p>
            <span className="testimonial-name">Marcus, 27</span>
          </div>
        </div>
      </div>

      {/* 푸터 영역 – 랜딩 페이지에서만 보임 */}
      <footer className="landing-footer">
        <button onClick={openBusinessModal} className="footer-button">
          사업자 정보
        </button>
        <button onClick={() => setShowInquiryModal(true)} className="footer-button">
          문의하기
        </button>
        <div className="footer-link-group">
          <a
            href="https://how2play.club/marketing-consent"
            target="_blank"
            rel="noreferrer"
            className="footer-link"
          >
            마케팅 정보 수신 동의
          </a>
          <a
            href="https://how2play.club/privacy"
            target="_blank"
            rel="noreferrer"
            className="footer-link"
          >
            개인정보처리방침
          </a>
          <a
            href="https://how2play.club/terms"
            target="_blank"
            rel="noreferrer"
            className="footer-link"
          >
            서비스 이용약관
          </a>
        </div>
      </footer>

      {/* 사업자 정보 모달 */}
      {showBusinessModal && (
        <div className="modal-overlay" onClick={closeBusinessModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>사업자 정보</h3>
            <p>• 회사명: 장이신</p>
            <p>• 대표자: 신화랑</p>
            <p>• 사업자등록번호: 454-41-01363</p>
            <p>• 주소: 서울특별시 성북구 길음로 119</p>
            <button onClick={closeBusinessModal} className="close-modal-btn">
              닫기
            </button>
          </div>
        </div>
      )}

      {/* 문의하기 모달 */}
      {showInquiryModal && (
        <div className="modal-overlay" onClick={closeInquiryModal}>
          <div
            className="modal-content inquiry-modal"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>1:1 문의</h2>
            <form className="inquiry-form" onSubmit={handleSubmitInquiry}>
              <label htmlFor="inquiryType">문의 유형</label>
              <select
                id="inquiryType"
                value={inquiryType}
                onChange={(e) => setInquiryType(e.target.value)}
                required
              >
                <option value="" disabled>
                  질문 유형을 선택해주세요.
                </option>
                <option value="이용 문의">이용 문의</option>
                <option value="오류 신고">오류 신고</option>
                <option value="결제 관련">결제 관련</option>
                <option value="서비스 제안">서비스 제안</option>
                <option value="기타">기타</option>
              </select>

              <label htmlFor="email">이메일</label>
              <input
                type="email"
                id="email"
                placeholder="example@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <label htmlFor="message">내용</label>
              <textarea
                id="message"
                placeholder="문의 내용을 입력해 주세요."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={5}
                required
              />

              <div className="inquiry-agreement">
                <input
                  type="checkbox"
                  id="agree"
                  checked={agreed}
                  onChange={() => setAgreed(!agreed)}
                />
                <label htmlFor="agree">
                  이메일 사용을 위한 개인정보 처리에 동의합니다.
                </label>
              </div>

              <button type="submit" disabled={!agreed}>
                접수하기
              </button>
              {status && <p className="inquiry-status">{status}</p>}
            </form>
            <button className="close-modal-btn" onClick={closeInquiryModal}>
              닫기
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LandingPage;
