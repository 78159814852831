import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./FindPwPage.css";
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';


const FindPwPage = () => {
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phone: "",
    verificationCode: "",
  });

  const [isUserVerified, setIsUserVerified] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [error, setError] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  // ✅ 입력값 변경 핸들러
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const showNotificationPopup = (message) => {
    setNotificationText(message);
  };
  const cleanPhone = (phone) => {
    return phone.replace(/[^\d]/g, "");  // ✅ 숫자만 남기기 (0-9)
  };

  // ✅ 유저 정보 확인 요청
  const handleVerifyUser = async () => {
    try {
      console.log("🔹 유저 확인 요청:", formData);
      const cleanedPhone = cleanPhone(formData.phone);  // ✅ 정리된 phone 값 사용


      // ✅ email, name, phone을 올바르게 요청에 포함
      const response = await axios.get(`https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/verify-user`, {
        params: { 
          email: formData.email, 
          name: formData.name, 
          phone: cleanedPhone
        }
      });

      console.log("🔹 서버 응답:", response.data);

      if (response.data.exists) {
        setError("");
        setIsUserVerified(true);
        showNotificationPopup("정보가 확인되었습니다. 인증번호를 요청하세요.");





      } else {
        setError("입력한 정보와 일치하는 계정이 없습니다.");
      }
    } catch (error) {
      console.error("❌ 유저 확인 실패:", error);
      setError("서버 오류가 발생했습니다. 다시 시도해주세요.");
    }
};


  // ✅ 유저 확인 상태 변경될 때 로그 출력
  useEffect(() => {
    console.log("🔄 isUserVerified 상태 변경:", isUserVerified);
  }, [isUserVerified]);

  // ✅ 인증번호 전송 요청
  const handleSendVerificationCode = async () => {
    if (!isUserVerified) {
      showNotificationPopup("먼저 사용자 정보를 확인하세요.");

      return;
    }
  
    try {
      const code = Math.floor(100000 + Math.random() * 900000).toString();
      setGeneratedCode(code);
  
      // 📌 CoolSMS API 요청 데이터 수정 (배열로 감싸기)
      const messageData = {
        message: {
          to: formData.phone,
          from: '07080645411', // 실제 발신번호
          text: `We are how2play\n인증 코드: ${code}`,
          type: 'SMS',
        },
      };
  
      const apiKey = "NCSLENCCTLRPA43Q";  // 실제 API Key
      const apiSecret = "QOAXN2SL1GYHWM3XOSPX08OKMBJCQ6JO";  // 실제 API Secret
      const date = new Date().toISOString();
      const salt = generateUUID();
      const signature = await getSignature(apiSecret, date + salt);
  
      // 📌 HTTP 요청 헤더 수정
      const headers = {
        Authorization: `HMAC-SHA256 apiKey=${apiKey}, date=${date}, salt=${salt}, signature=${signature}`,
        "Content-Type": "application/json",
      };
  
      console.log("🔹 CoolSMS 요청 데이터:", JSON.stringify(messageData, null, 2));
  
      // CoolSMS API 호출
      const response = await axios.post("https://api.coolsms.co.kr/messages/v4/send", messageData, { headers });
  
      if (response.status === 200) {
        setIsCodeSent(true);
        //alert("인증번호가 전송되었습니다.");
        showNotificationPopup("인증번호가 전송되었습니다.");



      } else {
        throw new Error("CoolSMS 응답 오류");
      }
    } catch (error) {
      console.error("인증번호 전송 실패:", error);
      setError("인증번호 전송에 실패했습니다.");
    }
    //showNotificationPopup();

  };
  

  // ✅ 인증번호 검증
  const handleVerifyCode = () => {
    if (formData.verificationCode.trim() === generatedCode) {
      setIsCodeVerified(true);
      setError("");
      //alert("인증이 완료되었습니다. 비밀번호를 재설정하세요.");
      showNotificationPopup("인증이 완료되었습니다. 비밀번호를 재설정하세요.");



    } else {
      setIsCodeVerified(false);
      setError("인증번호가 일치하지 않습니다.");
    }
    showNotificationPopup("");
  };

  // ✅ 비밀번호 재설정 페이지 이동
  const handleNavigateToResetPassword = () => {
    if (!isCodeVerified) {
      //alert("인증을 완료해주세요.");
      showNotificationPopup("인증을 완료해주세요.");
      return;
    }
    navigate("/reset-password", { 
      state: {
      email: formData.email, 
      name: formData.name, 
      phone: formData.phone  } });
  };

  // ✅ UUID 생성 함수
  const generateUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  // ✅ CoolSMS 서명 생성 함수
  const getSignature = async (apiSecret, msg) => {
    const encoder = new TextEncoder();
    const key = await crypto.subtle.importKey(
      "raw",
      encoder.encode(apiSecret),
      { name: "HMAC", hash: "SHA-256" },
      false,
      ["sign"]
    );
  
    const signature = await crypto.subtle.sign("HMAC", key, encoder.encode(msg));
  
    return Array.from(new Uint8Array(signature))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  };
  

  return (
    <div className="find-pw-page">
      <h1 className="find-pw-title">비밀번호 찾기</h1>
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}


  
      <form className="find-pw-form">
        {/* ✅ 입력 필드에 className 추가 */}
        <input type="text" name="email" className="input-field" placeholder="이메일" onChange={handleInputChange} />
        <input type="text" name="name" className="input-field" placeholder="이름" onChange={handleInputChange} />
        <input type="text" name="phone" className="input-field" placeholder="전화번호 (- 없이 숫자만)" onChange={handleInputChange} />
  
        {/* ✅ 사용자 확인 버튼 */}
        <button type="button" className="verification-btn" onClick={handleVerifyUser}>
          사용자 확인
        </button>
  
        {isUserVerified && (
          <button type="button" className="verification-btn" onClick={handleSendVerificationCode} disabled={isCodeSent}>
            {isCodeSent ? "인증번호 재전송" : "인증번호 전송"}
          </button>
        )}
  
        {isCodeSent && (
          <>
            <input type="text" name="verificationCode" className="input-field" placeholder="인증번호 입력" onChange={handleInputChange} />
            <button type="button" className="verification-btn" onClick={handleVerifyCode}>
              인증번호 확인
            </button>
          </>
        )}
  
        {isCodeVerified && (
          <button type="button" className="find-pw-submit-btn" onClick={handleNavigateToResetPassword}>
            비밀번호 재설정
          </button>
        )}
      </form>
  
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};
  
export default FindPwPage;
