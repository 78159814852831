import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./ClubDetailPopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faMapMarkerAlt,
  faUser,
  faSmokingBan,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import NotificationPopup from "../../Components/NotificationPopup/NotificationPopup"; // 추가된 부분

/* ----------------- EnlargedModal (SECOND POPUP) ------------------ */
const EnlargedModal = ({ image, onClose }) => {
  return ReactDOM.createPortal(
    <div className="enlarged-image-modal-overlay" onClick={onClose}>
      <div
        className="enlarged-image-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        <img
          src={image}
          alt="Enlarged Club"
          className="enlarged-image"
          style={{
            width: "80vw",
            maxWidth: "400px",
            height: "auto",
          }}
        />
      </div>
    </div>,
    document.body
  );
};

/* --------------------- ClubDetailPopup (MAIN POPUP) --------------------- */
const ClubDetailPopup = ({ club, onClose }) => {
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [touchStartY, setTouchStartY] = useState(null);
  const [notification, setNotification] = useState("");

  useEffect(() => {
    if (club?.images) {
      console.log("Club images received:", club.images);
    }
  }, [club]);

  if (!club) return null;

  const imagesToShow =
    club.images && club.images.length > 0
      ? club.images
      : [
          "https://img.hankyung.com/photo/201903/01.19192804.1.jpg",
          "https://d2v80xjmx68n4w.cloudfront.net/members/portfolios/IuZMN1726133075.jpg?w=500",
          "https://i.pinimg.com/236x/71/c9/f9/71c9f9a3b951ea70337f539f0e46c848.jpg",
        ];

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const handleImageClick = (imageUrl) => {
    setEnlargedImage(imageUrl);
  };

  const handleInstagramClick = (e) => {
    e.stopPropagation();
    if (club.instagram_url) {
      window.open(club.instagram_url, "_blank");
    }
  };

  const handleAddressClick = (e) => {
    e.stopPropagation();
    if (club.address) {
      navigator.clipboard
        .writeText(club.address)
        .then(() => {
          setNotification("주소가 복사되었습니다.");
          setTimeout(() => setNotification(""), 2000);
        })
        .catch((err) => {
          console.error("주소 복사 실패", err);
        });
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e) => {
    if (touchStartY !== null) {
      const diff = e.changedTouches[0].clientY - touchStartY;
      if (diff > 100) {
        handleClose();
      }
    }
    setTouchStartY(null);
  };

  return (
    <div className="club-detail-popup-overlay" onClick={handleClose}>
      {/* NotificationPopup을 최상단에 렌더링 */}
      {notification && (
        <NotificationPopup
          message={notification}
          onClose={() => setNotification("")}
        />
      )}

      <div
        className={`club-detail-popup-container ${
          isClosing ? "slide-out" : "slide-in"
        }`}
        onClick={(e) => e.stopPropagation()}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <span className="popup-back-symbol-club" onClick={handleClose}>
          &#x2304;
        </span>

        <div className="club-detail-header">
          <h1 className="club-detail-title">{club.name}</h1>
          {club.instagram_url && (
            <button
              className="club-detail-instagram-button"
              onClick={handleInstagramClick}
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="fa-icon-instagram"
              />
            </button>
          )}
        </div>

        <div className="info-section">
          <h2 className="section-title">About</h2>
          <div className="info-box">
            <p
              className="club-detail-address"
              onClick={handleAddressClick}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />{" "}
              {club.address || "정보 없음"}
            </p>
            <p className="club-detail-time">
              <FontAwesomeIcon icon={faClock} className="icon" />{" "}
              {club.operating_hours || "정보 없음"}
            </p>
            <p className="club-detail-entry">
              <FontAwesomeIcon icon={faUser} className="icon" /> 입장료{" "}
              {club.price || "정보 없음"}
            </p>
            <p className="club-detail-smoking">
              <FontAwesomeIcon icon={faSmokingBan} className="icon" />{" "}
              {club.smoking_policy ? "가능" : "불가능"}
            </p>
            <p className="club-detail-smoking">
              <FontAwesomeIcon icon={faInfoCircle} className="icon" />{" "}
              {club.description || " "}
            </p>
            <p className="club-detail-match">
              플레이리스트와의 매치 퍼센트:
              <strong>
                {club.matchPercent
                  ? `  ${club.matchPercent}%`
                  : "  링크를 붙여넣어 매치퍼센트를 확인해보세요!"}
              </strong>
            </p>
          </div>
        </div>

        <div className="genre-section">
          <h2 className="section-title">Genre</h2>
          <div className="genre-box-container">
            {club.genres
              ? club.genres.split(",").map((genre, index) => (
                  <span key={index} className="genre-box">
                    {genre.trim()}
                  </span>
                ))
              : <span className="genre-box">정보 없음</span>}
          </div>
        </div>

        <div className="image-section">
          <div className="club-detail-images-multi">
            <div className="multi-image-container">
              {imagesToShow.map((img, index) => {
                const imageUrl = typeof img === "object" ? img.image_url : img;
                return (
                  <div key={index} className="image-wrapper">
                    <img
                      src={imageUrl}
                      alt={`Club Image ${index + 1}`}
                      className="multi-image"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleImageClick(imageUrl);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {enlargedImage && (
        <EnlargedModal
          image={enlargedImage}
          onClose={() => setEnlargedImage(null)}
        />
      )}
    </div>
  );
};

export default ClubDetailPopup;
