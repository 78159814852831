import React, { useEffect, useRef, useState, useCallback } from "react";
import "./MapPage.css";

// Kakao Map 스크립트를 단 한 번만 로드하기 위한 싱글톤 함수
const loadKakaoMapScript = (() => {
  let promise;
  return (apiKey) => {
    if (promise) return promise;
    promise = new Promise((resolve, reject) => {
      if (document.querySelector(`script[src*="dapi.kakao.com"]`)) {
        if (window.kakao && window.kakao.maps) {
          resolve();
        } else {
          document
            .querySelector(`script[src*="dapi.kakao.com"]`)
            .addEventListener("load", resolve);
        }
        return;
      }
      const script = document.createElement("script");
      script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${apiKey}&autoload=false&libraries=services`;
      script.async = true;
      script.onload = () => {
        if (window.kakao && window.kakao.maps) {
          window.kakao.maps.load(() => resolve());
        } else {
          reject(new Error("Kakao Maps SDK failed to load."));
        }
      };
      script.onerror = reject;
      document.head.appendChild(script);
    });
    return promise;
  };
})();

const MapPage = ({ clubs, selectedLocation, isNearbyActive, onSelectClub }) => {
  const mapRef = useRef(null);
  const geocoderRef = useRef(null);
  const userMarkerRef = useRef(null);
  const watchIdRef = useRef(null);
  const markersCacheRef = useRef(new Map()); // 클럽 id별 마커 캐시
  const coordsCacheRef = useRef(new Map());  // 클럽 id별 좌표 캐시
  const clubsRef = useRef(clubs);
  const [userCoords, setUserCoords] = useState(null);
  // 사용자가 지도에서 드래그나 줌 등 수동 조작했는지 여부를 나타내는 플래그
  const [hasUserMovedMap, setHasUserMovedMap] = useState(false);

  // clubs가 업데이트될 때마다 ref도 갱신
  useEffect(() => {
    clubsRef.current = clubs;
  }, [clubs]);

  // 선택한 지역이 변경되면 사용자의 수동 조작 플래그를 초기화 (새로운 지역 선택 시 자동 중심 이동 허용)
  useEffect(() => {
    setHasUserMovedMap(false);
    if (mapRef.current && selectedLocation) {
      handleCentering(mapRef.current);
    }
  }, [selectedLocation]);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_KAKAO_MAP_API_KEY;
    if (!apiKey) {
      console.error("Kakao Map API key is missing. Check your .env file.");
      return;
    }
    let map;
    loadKakaoMapScript(apiKey)
      .then(() => {
        const container = document.getElementById("map");
        if (!container) return;
        const options = {
          center: new window.kakao.maps.LatLng(37.5665, 126.9780), // 서울 기본 좌표
          level: 8,
        };
        map = new window.kakao.maps.Map(container, options);
        mapRef.current = map;
        geocoderRef.current = new window.kakao.maps.services.Geocoder();

        // 사용자가 지도 드래그 또는 줌 시작 시, 선택지역 자동중심화를 중단 (내 주변 모드는 예외)
        window.kakao.maps.event.addListener(map, "dragstart", () => {
          setHasUserMovedMap(true);
        });
        window.kakao.maps.event.addListener(map, "zoomstart", () => {
          setHasUserMovedMap(true);
        });

        // 사용자 위치 감시 시작
        watchUserPosition(map);
        // 초기 마커 생성 및 지도 중심 설정
        updateMarkersAndCenter();
      })
      .catch((error) => {
        console.error("Error loading Kakao Map:", error);
      });

    // 컴포넌트 언마운트 시 cleanup
    return () => {
      if (watchIdRef.current) {
        navigator.geolocation.clearWatch(watchIdRef.current);
      }
      markersCacheRef.current.forEach((overlay) => {
        if (overlay._cleanup) overlay._cleanup();
        overlay.setMap(null);
      });
      markersCacheRef.current.clear();
    };
  }, []);

  // 사용자 위치를 watchPosition을 통해 업데이트 (에러 핸들링 및 옵션 포함)
  const watchUserPosition = useCallback((map) => {
    if (!navigator.geolocation) {
      console.error("Geolocation is not supported by this browser.");
      return;
    }
    watchIdRef.current = navigator.geolocation.watchPosition(
      (pos) => {
        const { latitude, longitude } = pos.coords;
        setUserCoords({ lat: latitude, lng: longitude });
        const userLatLng = new window.kakao.maps.LatLng(latitude, longitude);
        if (!userMarkerRef.current) {
          userMarkerRef.current = new window.kakao.maps.Marker({
            map,
            position: userLatLng,
          });
        } else {
          userMarkerRef.current.setPosition(userLatLng);
        }
        // 내 주변 모드는 언제나 사용자 위치를 기준으로 중심 이동 (수동 조작 여부 무시)
        if (isNearbyActive) {
          map.setLevel(3);
          map.panTo(userLatLng);
        }
      },
      (err) => {
        console.error("Error watching position:", err);
      },
      { enableHighAccuracy: true, maximumAge: 10000, timeout: 10000 }
    );
  }, [isNearbyActive]);

  // 마커 업데이트 및 지도 중심 조정 (필터 변경이나 clubs 업데이트에 따라 호출)
  const updateMarkersAndCenter = useCallback(() => {
    const map = mapRef.current;
    if (!map) return;
    const geocoder = geocoderRef.current;
    if (!geocoder) return;

    // 기존 마커 중 더 이상 필요 없는 것 제거
    const desiredClubIds = new Set(clubs.map(c => c.id.toString()));
    markersCacheRef.current.forEach((overlay, clubId) => {
      if (!desiredClubIds.has(clubId)) {
        if (overlay._cleanup) overlay._cleanup();
        overlay.setMap(null);
        markersCacheRef.current.delete(clubId);
      }
    });

    // 각 클럽에 대해 마커 생성 (캐시 사용)
    clubs.forEach(club => {
      const clubKey = club.id.toString();
      if (markersCacheRef.current.has(clubKey)) return;
      if (club.address) {
        if (coordsCacheRef.current.has(clubKey)) {
          const coords = coordsCacheRef.current.get(clubKey);
          createClubMarker(club, coords);
        } else {
          // 캐시가 없으면 지오코딩 호출
          geocoder.addressSearch(club.address, (result, status) => {
            if (!clubsRef.current.some(c => c.id.toString() === clubKey)) return;
            if (status === window.kakao.maps.services.Status.OK && result && result[0]) {
              const coords = new window.kakao.maps.LatLng(result[0].y, result[0].x);
              coordsCacheRef.current.set(clubKey, coords);
              createClubMarker(club, coords);
            } else {
              console.warn(`Geocoding failed for club ID ${club.id}: ${status}`);
            }
          });
        }
      }
    });

    // 지도 중심 업데이트
    // 내 주변 모드는 항상 적용
    if (isNearbyActive && userCoords) {
      handleCentering(map);
    }
    // 선택한 지역은 수동 조작이 없을 때만 적용
    else if (selectedLocation && !hasUserMovedMap) {
      handleCentering(map);
    }
    // 초기 로딩 시 자동 중심 이동
    else if (!hasUserMovedMap) {
      handleCentering(map);
    }
  }, [clubs, hasUserMovedMap, isNearbyActive, selectedLocation, userCoords]);

  useEffect(() => {
    if (mapRef.current) {
      updateMarkersAndCenter();
    }
  }, [clubs, selectedLocation, isNearbyActive, userCoords, updateMarkersAndCenter]);

  // 클럽 마커 생성 – 클릭 및 키보드 이벤트 리스너를 추가하고 cleanup 함수도 정의
  const createClubMarker = (club, coords) => {
    const container = document.createElement("div");
    container.className = "custom-marker";

    const icon = document.createElement("img");
    icon.src = "/Images/FireIcon.png";
    icon.alt = "Fire Icon";
    icon.className = "fire-icon";

    const label = document.createElement("div");
    label.className = "marker-label";
    label.textContent = club.name;

    container.appendChild(icon);
    container.appendChild(label);

    // 접근성을 위한 속성 설정
    container.setAttribute("role", "button");
    container.setAttribute("aria-label", `${club.name} location`);
    container.setAttribute("tabindex", "0");

    // 이벤트 핸들러 정의 (클릭 및 키보드)
    const onClick = () => onSelectClub(club);
    const onKeyDown = (e) => {
      if (e.key === "Enter" || e.key === " ") {
        onSelectClub(club);
      }
    };
    container.addEventListener("click", onClick);
    container.addEventListener("keydown", onKeyDown);

    const customOverlay = new window.kakao.maps.CustomOverlay({
      map: mapRef.current,
      position: coords,
      content: container,
      clickable: true,
    });

    // cleanup 함수를 커스텀 오버레이에 첨부 (이벤트 제거용)
    customOverlay._cleanup = () => {
      container.removeEventListener("click", onClick);
      container.removeEventListener("keydown", onKeyDown);
    };
    markersCacheRef.current.set(club.id.toString(), customOverlay);
  };

  // 지도 중심 조정 함수
  // - 내 주변 모드는 항상 사용자 위치로 중심 이동
  // - 선택 지역은 사용자가 수동 조작하지 않은 경우에만 적용
  // - 그 외에는 전체 클럽 평균 좌표로 설정
  const handleCentering = (map) => {
    if (isNearbyActive && userCoords) {
      map.setLevel(3);
      const userLatLng = new window.kakao.maps.LatLng(userCoords.lat, userCoords.lng);
      map.panTo(userLatLng);
      return;
    }
    if (selectedLocation && !hasUserMovedMap) {
      const filteredClubs = clubs.filter(club => club.location === selectedLocation);
      const coordsArray = filteredClubs
        .map(club => coordsCacheRef.current.get(club.id.toString()))
        .filter(Boolean);
      if (coordsArray.length > 0) {
        const avgCoords = calculateAverageCoordinates(coordsArray);
        map.setLevel(3);
        map.panTo(new window.kakao.maps.LatLng(avgCoords.lat, avgCoords.lng));
      }
      return;
    }
    if (!hasUserMovedMap) {
      const allCoords = clubs
        .map(club => coordsCacheRef.current.get(club.id.toString()))
        .filter(Boolean);
      if (allCoords.length > 0) {
        const avgCoords = calculateAverageCoordinates(allCoords);
        map.setLevel(9);
        map.panTo(new window.kakao.maps.LatLng(avgCoords.lat, avgCoords.lng));
      }
    }
  };

  // 유틸리티 함수 – 평균 좌표 계산 (좌표 배열)
  const calculateAverageCoordinates = (coordinates) => {
    if (coordinates.length === 0) {
      return { lat: 37.5665, lng: 126.9780 };
    }
    const total = coordinates.reduce(
      (acc, curr) => ({
        lat: acc.lat + curr.getLat(),
        lng: acc.lng + curr.getLng(),
      }),
      { lat: 0, lng: 0 }
    );
    return {
      lat: total.lat / coordinates.length,
      lng: total.lng / coordinates.length,
    };
  };

  return (
    <div>
      <div id="map" style={{ width: "100%", height: "70vh" }} />
    </div>
  );
};

export default MapPage;
