// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Storage } from '@capacitor/storage';

export const loginWithToken = createAsyncThunk(
  'auth/loginWithToken',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/user',
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const user = response.data;
      await Storage.set({ key: 'token', value: token });
      await Storage.set({ key: 'user', value: JSON.stringify(user) });
      return { user, token };
    } catch (error) {
      await Storage.remove({ key: 'token' });
      await Storage.remove({ key: 'user' });
      return rejectWithValue(error.response?.data || 'Failed to login with token');
    }
  }
);

export const restoreAuthState = createAsyncThunk(
  'auth/restoreAuthState',
  async (_, { rejectWithValue }) => {
    try {
      const { value: token } = await Storage.get({ key: 'token' });
      const { value: user } = await Storage.get({ key: 'user' });
      if (token && user) {
        return { token, user: JSON.parse(user) };
      } else {
        return rejectWithValue('No auth data found');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk('auth/logoutUser', async () => {
  await Storage.remove({ key: 'token' });
  await Storage.remove({ key: 'user' });
  await Storage.remove({ key: 'matchPercentages' });
  return null;
});

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  isLoading: false,
  error: null,
  isGuest: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setGuestLogin(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.token = null;
      state.isGuest = true;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginWithToken.fulfilled, (state, action) => {
        const { token, user } = action.payload;
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = user;
        state.token = token;
        state.isGuest = false;
      })
      .addCase(loginWithToken.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.isGuest = false;
      })
      .addCase(restoreAuthState.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(restoreAuthState.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.isGuest = false;
      })
      .addCase(restoreAuthState.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.user = null;
        state.token = null;
        state.isGuest = false;
      });
  },
});

export const { setGuestLogin, setUser } = authSlice.actions;
export default authSlice.reducer;
