// DeepLinkListener.jsx
import { useEffect } from 'react';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { useDispatch } from 'react-redux';
import { loginWithToken } from '../store/slices/authSlice';
import { useNavigate } from 'react-router-dom';

const KAKAO_REVERSED_SCHEME = `kakaoa2e5d7e4428ee5a5fbc5c79c505aa56d://oauth`;
const GOOGLE_REVERSED_SCHEME = `com.googleusercontent.apps.657266059621-9khh7nolhotok3la8uc3u8a9bc2hi7rr:/oauth`;
const SPOTIFY_SCHEME = `how2play://oauth/spotify`;

// **추가:** 백엔드에서 토큰 교환 시 사용할 웹 redirect URI (카카오 콘솔에 등록된 URI)
const KAKAO_WEB_REDIRECT_URI = "https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/callback/kakao";

const DeepLinkListener = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handler = App.addListener('appUrlOpen', async (data) => {
      try {
        console.log('🔗 Deep link received:', data.url);

        if (data.url.startsWith(KAKAO_REVERSED_SCHEME)) {
          await handleOAuthCallback(data.url, 'kakao');
        } else if (data.url.startsWith(GOOGLE_REVERSED_SCHEME)) {
          await handleOAuthCallback(data.url, 'google');
        } else if (data.url.startsWith(SPOTIFY_SCHEME)) {
          await handleOAuthCallback(data.url, 'spotify');
        }

        await Browser.close();
      } catch (error) {
        console.error('❌ Error handling deep link:', error);
      }
    });

    return () => {
      if (handler && typeof handler.remove === 'function') {
        handler.remove();
      }
    };
  }, [navigate, dispatch]);

  const handleOAuthCallback = async (url, provider) => {
    const parts = url.split('?');
    if (parts.length > 1) {
      const params = new URLSearchParams(parts[1]);
      const code = params.get('code');

      if (code) {
        console.log(`🔑 Received ${provider} auth code:`, code);
        await exchangeAuthCodeForToken(code, provider);
      } else {
        console.warn(`⚠️ No 'code' param found for provider=${provider}`);
      }
    }
  };

  const exchangeAuthCodeForToken = async (code, provider) => {
    try {
      const requestBody = { code };

      if (provider === 'google') {
        const codeVerifier = localStorage.getItem('google_code_verifier');
        if (!codeVerifier) {
          console.error('⚠️ No stored code_verifier found for Google!');
          return;
        }
        requestBody.code_verifier = codeVerifier;
      }

      // **변경:** Kakao 토큰 교환 시 웹 redirect URI 사용
      if (provider === 'kakao') {
        requestBody.redirect_uri = KAKAO_WEB_REDIRECT_URI;
      }

      const response = await fetch(
        `https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/callback/${provider}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      console.log(`✅ ${provider} Backend Response:`, data);

      if (data.access_token) {
        dispatch(loginWithToken(data.access_token))
          .unwrap()
          .then((result) => {
            // result.user.phone가 없으면 전화번호 인증 페이지로 이동
            if (!result.user.phone) {
              console.log('📞 No phone number found. Navigating to phone-verification.');
              navigate('/phone-verification', { replace: true });
            } else {
              console.log('🚀 Navigating to /home after successful login.');
            navigate('/home', { replace: true });
            }
          })
          .catch((err) => {
            console.error(`❌ Redux loginWithToken failed (${provider}):`, err);
            navigate('/mypage', { replace: true });
          });
      } else {
        console.error(`❌ No access token returned for ${provider}!`);
      }
    } catch (err) {
      console.error(`❌ Error exchanging code for token (${provider}):`, err);
    }
  };

  return null;
};

export default DeepLinkListener;
