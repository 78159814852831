import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  HomeOutline, HomeSharp,
  CompassOutline, CompassSharp,
  HeartOutline, HeartSharp,
  PersonOutline, PersonSharp
} from 'react-ionicons';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();

  // 특정 경로에서는 Navbar 숨김
  if (location.pathname === '/phone-verification') {
    return null;
  } else {
    return (
      <nav className="navbar">
        <NavLink to="/home">
          {({ isActive }) => (
            isActive ? <HomeSharp color={'#fff'} /> : <HomeOutline color={'#ffffff'} />
          )}
        </NavLink>

        <NavLink to="/clubs">
          {({ isActive }) => (
            isActive ? <CompassSharp color={'#fff'} width={'25px'} height={'25px'} /> : <CompassOutline color={'#ffffff'} width={'25px'} height={'25px'}/>
          )}
        </NavLink>

        <NavLink to="/likes">
          {({ isActive }) => (
            isActive ? <HeartSharp color={'#fff'} width={'25px'} height={'25px'} /> : <HeartOutline color={'#ffffff'} width={'25px'} height={'25px'} />
          )}
        </NavLink>

        <NavLink to="/mypage-v2">
          {({ isActive }) => (
            isActive ? <PersonSharp color={'#fff'} /> : <PersonOutline color={'#ffffff'} />
          )}
        </NavLink>
      </nav>
    );
  }
};

export default Navbar;
