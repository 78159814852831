import React, { useState } from "react";
import axios from "axios";
import "./adminAnnouncementPage.css";

function AdminAnnouncementPage() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [pinned, setPinned] = useState(false);
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("전송 중...");

    try {
      // Retrieve the JWT token from localStorage
      const token = localStorage.getItem("CapacitorStorage.token");

      // Make the POST request
      const response = await axios.post(
        "https://fastapi-backend-657266059621.asia-northeast1.run.app/api/announcements/",
        { title, content, pinned },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStatus("공지사항이 성공적으로 등록되었습니다!");

      // Clear the form
      setTitle("");
      setContent("");
      setPinned(false);
    } catch (error) {
      console.error("Error posting announcement:", error);
      if (error.response && error.response.data) {
        setStatus(
          `오류가 발생했습니다: ${
            error.response.data.detail || "알 수 없는 오류"
          }`
        );
      } else {
        setStatus("오류가 발생했습니다.");
      }
    }
  };

  return (
    <div className="admin-announcement-container">
      <h1 className="admin-announcement-title">공지사항 등록</h1>
      <form className="admin-announcement-form" onSubmit={handleSubmit}>
        <label className="form-label">제목</label>
        <input
          type="text"
          className="form-input"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <label className="form-label">내용</label>
        <textarea
          className="form-textarea"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          rows={5}
          required
        />

        <label className="checkbox-label">
          <input
            type="checkbox"
            className="form-checkbox"
            checked={pinned}
            onChange={() => setPinned(!pinned)}
          />
          상단 고정
        </label>

        <button type="submit" className="form-submit-button">
          등록
        </button>
      </form>
      {status && <p className="status-message">{status}</p>}
    </div>
  );
}

export default AdminAnnouncementPage;
