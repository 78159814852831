import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./PaymentSuccess.css"; // 업데이트된 CSS 파일 적용

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function confirmPayment() {
      try {
        const token = localStorage.getItem("token");  // ✅ 토큰 가져오기
        const userResponse = await fetch(
          "https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/user",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userData = await userResponse.json();
        const clubId = searchParams.get("club_id") || localStorage.getItem("selectedClubId");
        const dayGroup = searchParams.get("day_group") || localStorage.getItem("selectedDayGroup"); // ✅ localStorage에서 가져오기
        const requestData = {
          orderId: searchParams.get("orderId"),
          amount: Number(searchParams.get("amount")),  // ✅ 숫자로 변환
          paymentKey: searchParams.get("paymentKey"),
          user: {
            id: userData.id,
            email: userData.email,
          },
          club_id: clubId,
          day_group: dayGroup,
        };
        console.log("📢 결제 확인 요청 데이터:", requestData); // ✅ 디버깅용 로그

        const response = await fetch(
          "https://fastapi-backend-657266059621.asia-northeast1.run.app/api/payment/confirm",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        const json = await response.json();

        if (!response.ok) {
          // 결제 실패 시 fail 페이지로 이동
          navigate(`/fail?message=${json.message}&code=${json.code}`);
          return;
        }

        // ✅ 결제 성공 시 추가 로직 (예: 티켓 발급 완료 메시지)
        console.log("✅ 결제 성공:", json);
      } catch (error) {
        console.error("🚨 결제 승인 요청 오류:", error);
      }
    }

    confirmPayment();
  }, [searchParams, navigate]);

  return (
    <div className="ticketsuccess-wrapper">
      <div className="ticketsuccess-box_section">
        <h2>✅ 결제 성공</h2>
        <div className="ticketsuccess-text_box">
          <h3 className="ticketsuccess-subtitle">최종 결제 내역</h3>
          <hr className="ticketsuccess-separator" />
          <p>{`주문번호: ${searchParams.get("orderId")}`}</p>
          <p>{`결제 금액: ${Number(searchParams.get("amount")).toLocaleString()}원`}</p>
          <p className="ticketsuccess-payment-key">{`Payment Key: ${searchParams.get("paymentKey")}`}</p>
        </div>
        <button
          className="ticketsuccess-confirm_button"
          onClick={() => navigate("/likes")}
        >
          구매한 입장권 확인하기
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
