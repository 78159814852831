import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./ResetPasswordPage.css";
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';


const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // ✅ FindPwPage에서 넘겨받은 유저 정보
  const email = location.state?.email || "";
  const name = location.state?.name || "";
  const phone = location.state?.phone || "";

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  // ✅ 입력값 변경 핸들러
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") setPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
  };
  const showNotificationPopup = (message) => {
    setNotificationText(message);
    
  };

  // ✅ 비밀번호 재설정 요청 (FormData 사용X, JSON으로 전송)
  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      setError("비밀번호가 일치하지 않습니다.");
      return;
    }

    try {
      const response = await axios.post(
        "https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/reset-password",
        {
          email,  // ✅ 변경됨
          name,   // ✅ 변경됨
          phone,  // ✅ 변경됨
          new_password: password, // ✅ 변경됨
        },
        {
          headers: { "Content-Type": "application/json" }
        }
      );
  
      //alert("비밀번호가 성공적으로 변경되었습니다!");
      showNotificationPopup("비밀번호가 성공적으로 변경되었습니다!");

      setTimeout(() => {
        navigate('/login');
      }, 2000);
      navigate("/login");
    } catch (error) {
      console.error("비밀번호 재설정 오류:", error);
      setError("비밀번호 재설정 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="reset-password-page">
      <h1 className="reset-password-title">비밀번호 재설정</h1>
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}
      <form className="reset-password-form">
        <input
          type="password"
          name="password"
          className="input-field"
          placeholder="새로운 비밀번호"
          onChange={handleInputChange}
        />
        <input
          type="password"
          name="confirmPassword"
          className="input-field"
          placeholder="비밀번호 확인"
          onChange={handleInputChange}
        />
        <button type="button" className="reset-password-btn" onClick={handleResetPassword}>
          비밀번호 변경
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default ResetPasswordPage;
