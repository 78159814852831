// src/Components/SearchBar/SearchBar.jsx
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Search } from 'react-ionicons';

function SearchBar({ onSearch }) {
  // This local state updates right away as the user types
  const [localQuery, setLocalQuery] = useState('');

  // Only notify the parent after the user stops typing for ~200ms
  const debouncedUpdate = useDebouncedCallback((value) => {
    onSearch(value);
  }, 200);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setLocalQuery(newValue);
    debouncedUpdate(newValue); // Calls parent's onSearch after a short delay
  };

  return (
    <div className="search-bar">
      {/* Only show the icon while the local search field is empty */}
      {localQuery === '' && (
        <Search 
          color="#ffffff" 
          width="18px" 
          height="18px" 
          className="search-icon" 
        />
      )}
      <input
        type="text"
        placeholder="      오늘 어디서 놀래?"
        value={localQuery}
        onChange={handleChange}
      />
    </div>
  );
}

export default SearchBar;
