import React, { useEffect, useState, useRef } from "react";
import "./LikesPage.css";
import ClubCard from "../../Components/ClubCard/ClubCard";
import ClubDetailPopup from "../../Components/ClubDetailPopup/ClubDetailPopup";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchLikedClubs,
  fetchAllClubs,
  toggleLikeClub,
} from "../../store/slices/clubsSlice";
import { useNavigate } from "react-router-dom";
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';

const LikesPage = () => {
  const [activeTab, setActiveTab] = useState("club");
  const [selectedClub, setSelectedClub] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [userTickets, setUserTickets] = useState([]);
  const [ticketPopupActive, setTicketPopupActive] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { likedClubs, allClubs } = useSelector((state) => state.clubs);
  const { user, isGuest } = useSelector((state) => state.auth);

  // ----------------------------------------------------------------
  // If user is a guest or not logged in, redirect to login page
  // ----------------------------------------------------------------
  useEffect(() => {
    if (!user && isGuest) {
      navigate("/login");
    }
  }, [user, isGuest, navigate]);

  // ----------------------------------------------------------------
  // Fetch liked clubs, all clubs, and user tickets when the user logs in
  // ----------------------------------------------------------------
  useEffect(() => {
    if (user) {
      dispatch(fetchLikedClubs(user.id));
      dispatch(fetchAllClubs());
      fetchUserTickets(user.id);
    }
  }, [dispatch, user]);

  // ----------------------------------------------------------------
  // Fetch user's tickets from the FastAPI backend
  // ----------------------------------------------------------------
  const fetchUserTickets = async (userId) => {
    try {
      const response = await fetch(
        `https://fastapi-backend-657266059621.asia-northeast1.run.app/api/tickets?user_id=${userId}`
      );
      if (!response.ok) throw new Error("입장권을 불러올 수 없습니다.");

      const data = await response.json();
      setUserTickets(data);
    } catch (error) {
      console.error("🚨 입장권 요청 실패:", error);
    }
  };

  // ----------------------------------------------------------------
  // Helper: 남은 유효 기간(일수) 계산
  // ----------------------------------------------------------------
  const calculateRemainingDays = (expirationDateStr) => {
    if (!expirationDateStr) return "";
    const expirationDate = new Date(expirationDateStr);
    const today = new Date();
    const diffTime = expirationDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? `${diffDays}일` : "만료됨";
  };

  // ----------------------------------------------------------------
  // Tab change handler
  // ----------------------------------------------------------------
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // If user moves to the "playlist" tab, re-fetch tickets
    if (tab === "playlist" && user) {
      fetchUserTickets(user.id);
    }
  };

  // ----------------------------------------------------------------
  // Navigate to clubs search page
  // ----------------------------------------------------------------
  const handleSearchClubs = () => {
    navigate("/clubs");
  };

  // ----------------------------------------------------------------
  // Open club detail popup
  // ----------------------------------------------------------------
  const handleCardClick = (club) => {
    setSelectedClub(club);
  };

  // ----------------------------------------------------------------
  // Close club detail popup
  // ----------------------------------------------------------------
  const handleClosePopup = () => {
    setSelectedClub(null);
  };

  // ----------------------------------------------------------------
  // Like/unlike a club
  // ----------------------------------------------------------------
  const showNotificationPopup = (message) => {
    setNotificationText(message);
  };
  const handleLikeClick = (club) => {
    if (!user) return;
    const isLiked = likedClubs.includes(club.id);

    dispatch(toggleLikeClub({ userId: user.id, clubId: club.id, isLiked }))
      .unwrap()
      .then(() => {
        if (isLiked) {
          showNotificationPopup("즐겨찾기에서 제거되었습니다");
          // If the currently opened popup is for this club, close it
          if (selectedClub && selectedClub.id === club.id) {
            setSelectedClub(null);
          }
        } else {
          showNotificationPopup("즐겨찾기에 추가되었습니다");
        }
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 2000);
      })
      .catch((error) =>
        console.error("Error toggling like in LikesPage:", error)
      );
  };

  // ----------------------------------------------------------------
  // Increment/decrement quantity to refund
  // ----------------------------------------------------------------
  const handleQuantityChange = (ticketId, delta) => {
    setUserTickets((prevTickets) =>
      prevTickets.map((ticket) => {
        if (ticket.id === ticketId) {
          const current = ticket.selectedRefundQuantity || 1;
          const newQuantity = Math.min(
            Math.max(current + delta, 1),
            ticket.quantity
          );
          return { ...ticket, selectedRefundQuantity: newQuantity };
        }
        return ticket;
      })
    );
  };

  // ----------------------------------------------------------------
  // Refund ticket
  // ----------------------------------------------------------------
  const handleRefundTicket = async (ticket) => {
    if (!window.confirm("해당 티켓을 환불하시겠습니까?")) return;
    try {
      const refundQuantity = ticket.selectedRefundQuantity || 1;
      // Unit price = total amount / total quantity
      const unitPrice = ticket.amount / ticket.quantity;
      const refundAmount = unitPrice * refundQuantity;

      const refundData = {
        paymentKey: ticket.payment_key,
        amount: refundAmount,
        refundQuantity: refundQuantity,
      };

      const response = await fetch(
        "https://fastapi-backend-657266059621.asia-northeast1.run.app/api/payment/refund",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(refundData),
        }
      );
      const json = await response.json();
      if (!response.ok) {
        //alert(`환불 실패: ${json.message}`);
        showNotificationPopup(`환불 실패: ${json.message}`);

        return;
      }
      //alert("환불 요청이 완료되었습니다.");
      showNotificationPopup("환불 요청이 완료되었습니다.");

      
      // Refresh ticket list after refund
      fetchUserTickets(user.id);
    } catch (error) {
      console.error("환불 요청 오류:", error);
      //alert("환불 요청 중 오류가 발생했습니다.");
      showNotificationPopup("환불 요청 중 오류가 발생했습니다.");

    }
  };

  // ----------------------------------------------------------------
  // Filter clubs to show only the ones the user has liked
  // ----------------------------------------------------------------
  const filteredClubs = allClubs.filter((club) => likedClubs.includes(club.id));

  // 필터링: 총 입장권 개수가 0인 Payment 항목은 제외
  const validTickets = userTickets.filter((ticket) => ticket.quantity > 0);

  // ----------------------------------------------------------------
  // SWIPE LOGIC FOR TABS
  // ----------------------------------------------------------------
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchStartY, setTouchStartY] = useState(null);

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    // You could track movement here if needed
  };

  const handleTouchEnd = (e) => {
    if (touchStartX === null || touchStartY === null) return;

    const diffX = touchStartX - e.changedTouches[0].clientX;
    const diffY = touchStartY - e.changedTouches[0].clientY;

    // Only consider a "horizontal" swipe if horizontal distance > vertical distance
    if (Math.abs(diffX) > Math.abs(diffY)) {
      // Swipe left: diffX > 0
      if (diffX > 50) {
        // If user swiped left on the "club" tab, go to "playlist"
        if (activeTab === "club") {
          setActiveTab("playlist");
          fetchUserTickets(user?.id);
        }
      }
      // Swipe right: diffX < 0
      else if (diffX < -50) {
        // If user swiped right on the "playlist" tab, go to "club"
        if (activeTab === "playlist") {
          setActiveTab("club");
        }
      }
    }

    // Reset
    setTouchStartX(null);
    setTouchStartY(null);
  };

  return (
    <div
      className="likes-page"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}

      {/* Tab Buttons */}
      <div className="likes-tabs">
        <button
          className={`likes-tab ${activeTab === "club" ? "active" : ""}`}
          onClick={() => handleTabChange("club")}
        >
          클럽
        </button>
        <button
          className={`likes-tab ${activeTab === "playlist" ? "active" : ""}`}
          onClick={() => handleTabChange("playlist")}
        >
          보유한 입장권
        </button>
      </div>

      {/* Club Tab */}
      {activeTab === "club" ? (
        filteredClubs.length === 0 ? (
          <div className="no-clubs-container">
            <button className="no-clubs-button" onClick={handleSearchClubs}>
              당신만의 클럽을 찾으러 가세요
            </button>
          </div>
        ) : (
          <div className="likes-club-list">
            {filteredClubs.map((club) => (
              <ClubCard
                key={club.id}
                club={club}
                isLiked={likedClubs.includes(club.id)}
                onCardClick={handleCardClick}
                onLikeClick={() => handleLikeClick(club)}
              />
            ))}
          </div>
        )
      ) : (
        // Playlist (Tickets) Tab
        <div>
          {validTickets.length === 0 ? (
            <p className="no-tickets">현재 보유한 입장권이 없습니다.</p>
          ) : (
            <div className="ticket-list">
              {validTickets.map((ticket) => {
                // Map day_group to "주중" or "주말"
                const dayGroupLabel =
                  ticket.day_group?.trim().toLowerCase() === "weekday"
                    ? "주중"
                    : "주말";

                return (
                  <div key={ticket.id} className="ticket-card">
                    {ticket.club.image && (
                      <img
                        src={ticket.club.image}
                        alt={ticket.club.name}
                        className="ticket-image"
                      />
                    )}
                    <div className="ticket-info">
                      <h3>
                        {dayGroupLabel} {ticket.club.name} 프리패스 입장권
                        {ticket.quantity && ` x ${ticket.quantity}`}
                      </h3>
                      <p>
                        남은 유효 기간:{" "}
                        {calculateRemainingDays(ticket.expiration_date)}
                      </p>
                      <p>결제 금액: {ticket.amount.toLocaleString()}원</p>
                      <div className="ticket-quantity">
                        <button
                          className="quantity-btn"
                          onClick={() => handleQuantityChange(ticket.id, -1)}
                          disabled={(ticket.selectedRefundQuantity || 1) <= 1}
                        >
                          -
                        </button>
                        <span>
                          {ticket.selectedRefundQuantity || 1}매
                        </span>
                        <button
                          className="quantity-btn"
                          onClick={() => handleQuantityChange(ticket.id, 1)}
                          disabled={
                            (ticket.selectedRefundQuantity || 1) >=
                            ticket.quantity
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="ticket-actions">
                      <button
                        className="use-ticket-btn"
                        onClick={() => {
                          setSelectedTicket(ticket);
                          setTicketPopupActive(true);
                        }}
                      >
                        티켓 사용하기
                      </button>
                      <button
                        className="refund-ticket-btn"
                        onClick={() => handleRefundTicket(ticket)}
                      >
                        환불하기
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {/* Club Detail Popup */}
      {selectedClub && (
        <ClubDetailPopup club={selectedClub} onClose={handleClosePopup} />
      )}

      {/* Ticket Popup */}
      <div className={`ticket-popup ${ticketPopupActive ? "active" : ""}`}>
        <div className="ticket-content">
          {/* Drag handle (visual bar at top) */}
          <div className="ticket-handle"></div>

          {/* The actual "black ticket" portion */}
          <div className="ticket-inner">
            <h2 className="ticket-title">
              {selectedTicket
                ? `${
                    selectedTicket.day_group?.trim().toLowerCase() === "weekday"
                      ? "주중"
                      : "주말"
                  } ${selectedTicket.club.name} 프리패스 입장권`
                : "프리패스 입장권"}
            </h2>

            <p className="ticket-entrance-label">입장 가능 시간</p>
            <p className="time-label">23:00PM ~ 01:00AM</p>

            <hr className="ticket-separator" />

            <p className="ticket-instruction-large">
              직원에게 이 화면을 보여주시고 입장해주세요.
            </p>

            <button
              className="close-ticket-btn"
              onClick={() => {
                setTicketPopupActive(false);
                setSelectedTicket(null);
              }}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LikesPage;
