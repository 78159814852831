// src/Pages/Phone/PhoneVerificationPage.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../SignupPage/SignupPage.css';
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';


import { setUser } from '../../store/slices/authSlice';

const PhoneVerificationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  // ✅ 알림 표시 함수
  const showNotificationPopup = (message) => {
    setNotificationText(message);
  };
  

  // Redux 스토어에서 user, token, isAuthenticated 가져오기
  const { user, token, isAuthenticated } = useSelector((state) => state.auth);

  // 폼 상태
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);

  const [error, setError] = useState('');

  // 체크박스 상태
  const [agreeAll, setAgreeAll] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreePrivacy, setAgreePrivacy] = useState(false);
  const [agreeAge, setAgreeAge] = useState(false);
  // 선택적 마케팅 동의
  const [marketingConsent, setMarketingConsent] = useState({
    email: false,
    sms: false,
    kakao: false,
  });
  
  // 이미 인증된 상태라면 클럽 페이지로 이동
  useEffect(() => {
    if (isAuthenticated && user?.isVerified) {
      navigate('/home');
    }
  }, [isAuthenticated, user, navigate]);

  // 약관 전체동의
  const handleAgreeAll = (e) => {
    const checked = e.target.checked;
    setAgreeAll(checked);
    setAgreeTerms(checked);
    setAgreePrivacy(checked);
    setAgreeAge(checked);
    setMarketingConsent({
      email: checked,
      sms: checked,
      kakao: checked,
    });  };

  // 개별 체크박스 상태 업데이트
  const handleCheckboxChange = (e, setter) => {
    const checked = e.target.checked;
    setter(checked);

    // 전체 동의 해제
    if (!checked) {
      setAgreeAll(false);
    }
  };

  // 인증번호 전송
  const handleSendCode = async () => {
    try {
      if (!phone.trim()) {
        setError('전화번호를 입력하세요.');
        return;
      }
      setError('');

      const code = Math.floor(100000 + Math.random() * 900000);
      setGeneratedCode(code.toString());

      const messageData = {
        message: {
          to: phone,
          from: '07080645411', // 실제 발신번호
          text: `We are how2play\n인증 코드: ${code}`,
          type: 'SMS',
        },
      };

      // CoolSMS를 위한 인증 정보
      const apiKey = 'NCSLENCCTLRPA43Q'; // 실제 API Key
      const apiSecret = 'QOAXN2SL1GYHWM3XOSPX08OKMBJCQ6JO'; // 실제 API Secret
      const date = new Date().toISOString();
      const salt = generateUUID();
      const signature = await getSignature(apiSecret, date + salt);

      const headers = {
        Authorization: `HMAC-SHA256 apiKey=${apiKey}, date=${date}, salt=${salt}, signature=${signature}`,
        'Content-Type': 'application/json',
      };

      // 인증번호 발송 요청
      await axios.post('https://api.coolsms.co.kr/messages/v4/send', messageData, { headers });
      setIsCodeSent(true);
      //alert('인증번호가 전송되었습니다.');
      showNotificationPopup("인증번호가 전송되었습니다.");
    } catch (err) {
      console.error(err);
      setError('인증번호 전송에 실패했습니다.');
    }
  };

  // 인증번호 검증
  const handleVerifyCode = () => {
    if (!verificationCode.trim()) {
      setError('인증번호를 입력하세요.');
      return;
    }
    if (verificationCode.trim() === generatedCode) {
      setIsCodeVerified(true);
      setError('');
      //alert('인증이 완료되었습니다.');
      showNotificationPopup("인증이 완료되었습니다.");
    } else {
      setIsCodeVerified(false);
      setError('인증번호가 일치하지 않습니다.');
    }
  };

  // 최종 완료 버튼
  const handleComplete = async () => {
    // 체크박스 필수 항목(이용약관, 개인정보, 나이) 확인
    if (!agreeTerms || !agreePrivacy || !agreeAge) {
      setError('필수 약관에 동의해주세요.');
      return;
    }

    if (!isCodeVerified) {
      setError('전화번호 인증이 필요합니다.');
      return;
    }

    setError('');

    try {
      // 서버에 phone 저장
      const response = await axios.post(
        'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/verify-phone',
        { phone, marketing_consent: marketingConsent },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // 서버에서 반환된 사용자 정보
      const updatedUser = response.data.user;

      // Redux store의 user 정보 업데이트
      dispatch(setUser(updatedUser));

      //alert('전화번호 등록이 완료되었습니다.');
      showNotificationPopup("전화번호 등록이 완료되었습니다.");
      //navigate('/clubs');
      setTimeout(() => {
        navigate('/clubs');
      }, 2000);
    } catch (err) {
      console.error(err);
      setError('서버와의 통신 중 오류가 발생했습니다.');
    }
  };

  // CoolSMS 서명 생성 함수
  const getSignature = async (apiSecret, msg) => {
    const encoder = new TextEncoder();
    const key = await crypto.subtle.importKey(
      'raw',
      encoder.encode(apiSecret),
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign']
    );
    const signature = await crypto.subtle.sign('HMAC', key, encoder.encode(msg));
    return Array.from(new Uint8Array(signature))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
  };

  // UUID 생성 함수
  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  // ─────────────────────────────────────────────────────────────────
  // Here’s the key logic for making the button uncolored/disabled
  // ─────────────────────────────────────────────────────────────────
  const canSubmit =
    isCodeVerified && agreeTerms && agreePrivacy && agreeAge;

  return (
    <div className="signup-page">
{/* notificationText가 존재하면 NotificationPopup 렌더링 */}
{notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}
            <h1 className="signup-title">전화번호 인증</h1>


      <form className="signup-form" onSubmit={(e) => e.preventDefault()}>
        {/* 전화번호 입력 */}
        <div className="input-button-container">
          <input
            type="text"
            className="input-field"
            value={phone}
            placeholder="전화번호 (- 없이 숫자만)"
            onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
          />
          <button 
            type="button"
            className="send-code-btn"
            onClick={handleSendCode}
            disabled={isCodeSent}
          >
            {isCodeSent ? '인증번호 재전송' : '인증번호 전송'}
          </button>
        </div>

        {/* 인증번호 입력 */}
        {isCodeSent && (
          <div className="verification-input-button-container">
            <input
              type="text"
              className="input-field"
              value={verificationCode}
              placeholder="인증번호 입력"
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <button type="button" className="verify-code-btn" onClick={handleVerifyCode}>
              인증 확인
            </button>
          </div>
        )}

        {/* 에러 메시지 */}
        {error && <p className="error-message">{error}</p>}

        {/* 체크박스 영역 */}
        <div className="agreements">
          <label>
            <input
              type="checkbox"
              name="agreeAll"
              checked={agreeAll}
              onChange={handleAgreeAll}
            />
            모두 동의합니다.
          </label>
          <label>
            <input
              type="checkbox"
              name="agreeTerms"
              checked={agreeTerms}
              onChange={(e) => handleCheckboxChange(e, setAgreeTerms)}
            />
            <span>
            <a href="/terms"  rel="noopener noreferrer">
            How2Play 이용약관
          </a>{' '}
          동의 (필수)
          </span>     
          </label>
          <label>
            <input
              type="checkbox"
              name="agreePrivacy"
              checked={agreePrivacy}
              onChange={(e) => handleCheckboxChange(e, setAgreePrivacy)}
            />
            <span>
            <a href="/privacy"  rel="noopener noreferrer">
            개인정보 처리 방침          </a>{' '}
          동의 (필수)
          </span>
          </label>
          <label>
            <input
              type="checkbox"
              name="agreeAge"
              checked={agreeAge}
              onChange={(e) => handleCheckboxChange(e, setAgreeAge)}
            />
            만 14세 이상입니다. (필수)
          </label>
          <label>
            <input
              type="checkbox"
              name="agreeMarketing"
              checked={marketingConsent.email && marketingConsent.sms && marketingConsent.kakao}
              onChange={(e) => {
                const checked = e.target.checked;
                setMarketingConsent({
                  email: checked,
                  sms: checked,
                  kakao: checked,
                });
              }}
            />
            <span>
            <a href="/marketing-consent"  rel="noopener noreferrer">
            마케팅 수신 동의 (선택)       
          </a>{' '}
          
          </span>
        </label>
        </div>

        {/* 완료 버튼 (disabled if conditions not met) */}
        <button
          type="button"
          className={`signup-submit-btn ${canSubmit ? 'active' : ''}`}
          disabled={!canSubmit}
          onClick={handleComplete}
        >
          완료
        </button>
      </form>
    </div>
  );
};

export default PhoneVerificationPage;
