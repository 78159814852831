import React, { useState } from 'react';
import './SignupPage.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginWithToken } from '../../store/slices/authSlice';
import axios from 'axios';
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';

const SignupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [notificationText, setNotificationText] = useState("");

  const showNotificationPopup = (message) => {
    setNotificationText(message);
  };

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    phone: '',
    birthdate: '',
    gender: '',
    agreeAll: false,
    agreeTerms: false,
    agreePrivacy: false,
    agreeAge: false,
    marketingConsent: {
      email: false,
      sms: false,
      kakao: false,
    },
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    phone: '',
    agreeTerms: '',
    agreePrivacy: '',
    agreeAge: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [codeError, setCodeError] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [isCodeVerified, setIsCodeVerified] = useState(false);

  const computeValidationErrors = (data) => {
    const newErrors = { ...errors };

    if (!data.email.trim()) {
      newErrors.email = '이메일을 입력해주세요.';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      newErrors.email = '유효한 이메일 주소를 입력해주세요.';
    } else {
      newErrors.email = '';
    }

    if (!data.password.trim()) {
      newErrors.password = '비밀번호를 입력해주세요.';
    } else if (data.password.length < 8) {
      newErrors.password = '비밀번호는 8자 이상이어야 합니다.';
    } else {
      newErrors.password = '';
    }

    if (!data.confirmPassword.trim()) {
      newErrors.confirmPassword = '비밀번호 확인을 입력해주세요.';
    } else if (data.password !== data.confirmPassword) {
      newErrors.confirmPassword = '비밀번호가 일치하지 않습니다.';
    } else {
      newErrors.confirmPassword = '';
    }

    if (!data.name.trim()) {
      newErrors.name = '이름을 입력해주세요.';
    } else {
    newErrors.name = '';
    }

    if (data.phone.trim()) {
      if (!/^\d{10,15}$/.test(data.phone)) {
        newErrors.phone = '유효한 전화번호를 입력해주세요.';
      } else {
        newErrors.phone = '';
      }
    } else {
      newErrors.phone = '';
    }

    if (!data.agreeTerms) {
      newErrors.agreeTerms = '이용 약관에 동의해야 합니다.';
    } else {
      newErrors.agreeTerms = '';
    }

    if (!data.agreePrivacy) {
      newErrors.agreePrivacy = '개인정보 처리 방침에 동의해야 합니다.';
    } else {
      newErrors.agreePrivacy = '';
    }

    if (!data.agreeAge) {
      newErrors.agreeAge = '만 14세 이상이어야 합니다.';
    } else {
      newErrors.agreeAge = '';
    }

    return newErrors;
  };

  const handlePartialValidation = (name, value) => {
    let errorMsg = '';

    if (name === 'email') {
      if (value.length > 5 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        errorMsg = '유효한 이메일 주소를 입력해주세요.';
      }
    }

    if (name === 'password') {
      if (value.length > 5 && value.length < 8) {
        errorMsg = '비밀번호는 8자 이상이어야 합니다.';
      }
    }

    if (name === 'confirmPassword') {
      if (value.length > 5 && value !== formData.password) {
        errorMsg = '비밀번호가 일치하지 않습니다.';
      }
    }

    setErrors((prev) => ({
      ...prev,
      [name]: errorMsg,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value: rawValue } = e.target;
    let value = rawValue;

    if (name === 'phone') {
      value = rawValue.replace(/\D/g, '');
    }

    setFormData((prev) => ({ ...prev, [name]: value }));

    if (['email', 'password', 'confirmPassword'].includes(name)) {
      handlePartialValidation(name, value);
    }

    if (submitted) {
      const newErrors = computeValidationErrors({
        ...formData,
        [name]: value,
      });
      setErrors(newErrors);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));

    if (submitted) {
      const newErrors = computeValidationErrors({
        ...formData,
        [name]: checked,
      });
      setErrors(newErrors);
    }
  };

  const handleMarketingConsentChange = (channel) => {
    setFormData((prev) => ({
      ...prev,
      marketingConsent: {
        ...prev.marketingConsent,
        [channel]: !prev.marketingConsent[channel],
      },
    }));
  };

  const handleAgreeAll = (e) => {
    const { checked } = e.target;
    const updatedData = {
      ...formData,
      agreeAll: checked,
      agreeTerms: checked,
      agreePrivacy: checked,
      agreeAge: checked,
      marketingConsent: {
        ...formData.marketingConsent,
        email: checked,
        sms: checked,
        kakao: checked,
      },
    };
    setFormData(updatedData);

    if (submitted) {
      const newErrors = computeValidationErrors(updatedData);
      setErrors(newErrors);
    }
  };

  const handleSendCode = async () => {
    try {
      const phoneNumber = formData.phone;
      const code = Math.floor(100000 + Math.random() * 900000);
      setGeneratedCode(code);

      const messageData = {
        message: {
          to: phoneNumber,
          from: '07080645411',
          text: `We are how2play\n인증 코드: ${code}`,
          type: 'SMS',
        },
      };

      const date = new Date().toISOString();
      const salt = uniqueId();
      const combinedString = date + salt;
      const YOUR_API_KEY = 'NCSLENCCTLRPA43Q';
      const apiSecret = 'QOAXN2SL1GYHWM3XOSPX08OKMBJCQ6JO';
      const signature = await getSignature(apiSecret, combinedString);

      const headers = {
        Authorization: `HMAC-SHA256 apiKey=${YOUR_API_KEY}, date=${date}, salt=${salt}, signature=${signature}`,
        'Content-Type': 'application/json',
      };

      await axios.post('https://api.coolsms.co.kr/messages/v4/send', messageData, { headers });

      setIsCodeSent(true);
      setCodeError('');
      showNotificationPopup("인증 코드가 전송되었습니다. 확인 후 입력해주세요.");
    } catch (error) {
      setCodeError('인증번호 전송에 실패했습니다.');
    }
  };

  const handleVerifyCode = () => {
    if (verificationCode === generatedCode.toString()) {
      showNotificationPopup("인증번호가 확인되었습니다.");
      setCodeError('');
      setIsCodeVerified(true);
    } else {
      setCodeError('인증번호가 일치하지 않습니다.');
      setIsCodeVerified(false);
    }
  };
/*
  const handleResendCode = () => {
    setIsCodeSent(false);
    handleSendCode();
  }; */

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    const newErrors = computeValidationErrors(formData);
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((msg) => msg !== '');
    
    const phoneValidationRequired = formData.phone.trim() !== '';
    if (hasErrors || (phoneValidationRequired && !isCodeVerified)) {
      showNotificationPopup("입력하신 정보를 다시 확인해주세요.");
      return;
    }

    try {
      const response = await axios.post(
        'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/signup',
        {
          email: formData.email,
          password: formData.password,
          name: formData.name,
          phone: formData.phone || null,
          birthdate: formData.birthdate || null,
          gender: formData.gender || null,
          marketing_consent: {
            email: formData.marketingConsent.email,
            sms: formData.marketingConsent.sms,
            kakao: formData.marketingConsent.kakao,
          },
        }
      );

      const { access_token } = response.data;
      // loginWithToken thunk가 Storage에 저장하므로 직접 저장할 필요 없음
      await dispatch(loginWithToken(access_token));
      navigate('/clubs');
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.detail);
      } else {
        setError('회원가입 중 오류가 발생했습니다.');
      }
    }
  };

  const uniqueId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const getSignature = async (apiSecret, msg) => {
    const encoder = new TextEncoder();
    const keyData = encoder.encode(apiSecret);
    const key = await crypto.subtle.importKey(
      'raw',
      keyData,
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign']
    );

    const msgData = encoder.encode(msg);
    const signature = await crypto.subtle.sign('HMAC', key, msgData);

    return Array.from(new Uint8Array(signature))
      .map((b) => ('00' + b.toString(16)).slice(-2))
      .join('');
  };

  return (
    <div className="signup-page">
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}
      <h1 className="signup-title">회원가입</h1>

      <form onSubmit={handleSubmit} className="signup-form">
        {(submitted || formData.email.length > 5) && errors.email && (
          <p className="error-message">{errors.email}</p>
        )}
        <input
          type="email"
          name="email"
          placeholder="이메일 *"
          value={formData.email}
          onChange={handleInputChange}
          className="input-field"
          required
        />

        {(submitted || formData.password.length > 5) && errors.password && (
          <p className="error-message">{errors.password}</p>
        )}
        <input
          type="password"
          name="password"
          placeholder="비밀번호 (영문/숫자/특수문자 조합 8자 이상) *"
          value={formData.password}
          onChange={handleInputChange}
          className="input-field"
          required
        />

        {(submitted || formData.confirmPassword.length > 5) && errors.confirmPassword && (
          <p className="error-message">{errors.confirmPassword}</p>
        )}
        <input
          type="password"
          name="confirmPassword"
          placeholder="비밀번호 확인 *"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          className="input-field"
          required
        />

        <input
          type="text"
          name="name"
          placeholder="이름"
          value={formData.name}
          onChange={handleInputChange}
          className="input-field"
        />

        <div className="input-button-container">
          <input
            type="text"
            name="phone"
            placeholder="휴대폰 번호 (선택)"
            value={formData.phone}
            onChange={handleInputChange}
            className="input-field"
          />
          <button
            type="button"
            className="send-code-btn"
            onClick={handleSendCode}
            disabled={isCodeSent}
          >
            {isCodeSent ? '인증번호 전송 완료' : '인증번호 전송'}
          </button>
        </div>

        {isCodeSent && (
          <div className="verification-input-button-container">
            <input
              type="text"
              placeholder="인증번호 입력"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              className="input-field"
              required
            />
            <button type="button" className="verify-code-btn" onClick={handleVerifyCode}>
              인증번호 확인
            </button>
            {codeError && <p className="error-message">{codeError}</p>}
          </div>
        )}

        <div className="gender-selection">
          <p className="gender-label">성별 선택</p>
          <div className="gender-buttons">
            <button
              type="button"
              className={`gender-btn ${formData.gender === 'Male' ? 'active' : ''}`}
              onClick={() => {
                setFormData((prev) => ({ ...prev, gender: 'Male' }));
                if (submitted) {
                  const newErrors = computeValidationErrors({ ...formData, gender: 'Male' });
                  setErrors(newErrors);
                }
              }}
            >
              남자
            </button>
            <button
              type="button"
              className={`gender-btn ${formData.gender === 'Female' ? 'active' : ''}`}
              onClick={() => {
                setFormData((prev) => ({ ...prev, gender: 'Female' }));
                if (submitted) {
                  const newErrors = computeValidationErrors({ ...formData, gender: 'Female' });
                  setErrors(newErrors);
                }
              }}
            >
              여자
            </button>
            <button
              type="button"
              className={`gender-btn ${formData.gender === 'Other' ? 'active' : ''}`}
              onClick={() => {
                setFormData((prev) => ({ ...prev, gender: 'Other' }));
                if (submitted) {
                  const newErrors = computeValidationErrors({ ...formData, gender: 'Other' });
                  setErrors(newErrors);
                }
              }}
            >
              기타
            </button>
          </div>
        </div>

        <div className="agreements">
          <label>
            <input
              type="checkbox"
              name="agreeAll"
              checked={formData.agreeAll}
              onChange={handleAgreeAll}
            />
            모두 동의합니다.
          </label>
          <label>
            <input
              type="checkbox"
              name="agreeTerms"
              checked={formData.agreeTerms}
              onChange={handleCheckboxChange}
            />
          <span>
            <a href="/terms"  rel="noopener noreferrer">
            How2Play 이용약관
          </a>{' '}
          동의 (필수)
          </span>          
          </label>
          {submitted && errors.agreeTerms && <p className="error-message">{errors.agreeTerms}</p>}

          <label>
            <input
              type="checkbox"
              name="agreePrivacy"
              checked={formData.agreePrivacy}
              onChange={handleCheckboxChange}
            />
            
            <span>
            <a href="/privacy"  rel="noopener noreferrer">
            개인정보 처리 방침          </a>{' '}
          동의 (필수)
          </span>
          </label>
          {submitted && errors.agreePrivacy && (
            <p className="error-message">{errors.agreePrivacy}</p>
          )}

          <label>
            <input
              type="checkbox"
              name="agreeAge"
              checked={formData.agreeAge}
              onChange={handleCheckboxChange}
            />
            만 14세 이상입니다. (필수)
          </label>
          {submitted && errors.agreeAge && <p className="error-message">{errors.agreeAge}</p>}

          <div className="marketing-section">
          <h3>
              마케팅 수신 동의 (선택)
              {/* 마케팅 동의 상세 페이지로 이동하는 링크 */}
              <a 
                href="/marketing-consent" 
                rel="noopener noreferrer"
                className="marketing-link"
                style={{ marginLeft: '10px', fontSize: '14px', textDecoration: 'underline' }}
              >
                자세히 보기
              </a>
            </h3>
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={formData.marketingConsent.email}
                onChange={() => handleMarketingConsentChange('email')}
              />
              이메일
            </label>
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={formData.marketingConsent.sms}
                onChange={() => handleMarketingConsentChange('sms')}
              />
              문자
            </label>
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={formData.marketingConsent.kakao}
                onChange={() => handleMarketingConsentChange('kakao')}
              />
              카카오톡
            </label>
          </div>
        </div>

        {error && <p className="error-message">{error}</p>}

        <button type="submit" className="signup-submit-btn active">
          가입하기
        </button>
      </form>
    </div>
  );
};

export default SignupPage;
