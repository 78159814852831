import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();
  const [showDetails, setShowDetails] = useState(false);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="company-info">
          <div className="company-info-header">
            <h4>사업자 정보</h4>
            <button
              className="dropdown-button"
              onClick={() => setShowDetails(prev => !prev)}
            >
              {showDetails ? "접기" : "자세히 보기"}
            </button>
          </div>
          {showDetails && (
            <div className="company-details">
              <p><strong>상호:</strong> 장이신</p>
              <p><strong>대표자:</strong> 신화랑</p>
              <p>
                <strong>주소:</strong> 서울특별시 성북구 길음로 119, 207동 301호 (길음동, 길음뉴타운)
              </p>
              <p><strong>사업자등록번호:</strong> 454-41-01363</p>
              <p><strong>업태:</strong> 정보통신업 / 도매 및 소매업</p>
              <p>
                <strong>종목:</strong> 응용 소프트웨어 개발 및 공급업 / 전자상거래 소매 중개업
              </p>
              <p><strong>연락처:</strong> 070-8064-5411</p>
            </div>
          )}
        </div>
        <div className="legal-links">
          <a href="/terms" onClick={() => navigate('/terms')}>이용약관</a> |{" "}
          <a href="/privacy" onClick={() => navigate('/privacy')}>개인정보처리방침</a> |{" "}
          <a href="/marketing-consent" onClick={() => navigate('/marketing-consent')}>
            마케팅 정보 수신동의
          </a>
        </div>
        <p className="copyright">
          &copy; {new Date().getFullYear()} How2Play. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
