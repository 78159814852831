import { useSearchParams, useNavigate } from "react-router-dom";
import "./PaymentFail.css"; // 업데이트된 CSS 파일 적용

const PaymentFail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <div className="ticketfail-wrapper">
      <div className="ticketfail-box_section">
        <h2>🚨 결제 실패</h2>
        <div className="ticketfail-text_box">
          <p>{`에러 메시지: ${searchParams.get("message")}`}</p>
          <hr className="ticketfail-separator" />
          <p>{`에러 코드: ${searchParams.get("code")}`}</p>
        </div>
        <button
          className="ticketfail-confirm_button"
          onClick={() => navigate("/ticket-info")}
        >
          다시 결제하러 가기
        </button>
      </div>
    </div>
  );
};

export default PaymentFail;
