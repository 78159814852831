import React, { useState, useEffect } from 'react';
import './EditInfo.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { loginWithToken, logoutUser } from '../../store/slices/authSlice';
import axios from 'axios';

const EditInfo = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // 알림 메시지
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState('');

  // 원래 폰 번호 (DB에서 불러온) -> 비교용
  const [originalPhone, setOriginalPhone] = useState('');

  // 폼 데이터
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    birthdate: '',
    gender: '',
    phone: '',
    accountBank: '',
    accountNumber: '',
    accountHolder: '',
    // 기본값 (서버에서 가져올 때 덮어쓸 예정)
    marketingConsent: {
      email: false,
      sms: false,
      kakao: false,
    },
  });

  // 휴대폰 변경 여부
  const [isPhoneChanged, setIsPhoneChanged] = useState(false);
  // 휴대폰 인증 여부
  const [isCodeVerified, setIsCodeVerified] = useState(false);

  // SMS 인증 관련
  const [verificationCode, setVerificationCode] = useState('');
  const [generatedCode, setGeneratedCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);

  // -------------------------
  // 1) 사용자 정보 불러오기
  // -------------------------
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!token) return;
      try {
        const response = await axios.get(
          'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/info',
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const userData = response.data;

        setFormData((prev) => ({
          ...prev,
          // 아이디는 서버에서 email로 사용한다고 가정
          id: userData.email || '',
          name: userData.name || '',
          birthdate: userData.birthdate || '',
          gender: userData.gender || '',
          phone: userData.phone || '',
          accountBank: userData.accountBank || '',
          accountNumber: userData.accountNumber || '',
          accountHolder: userData.accountHolder || '',
          marketingConsent: userData.marketing_consent || {
            email: false,
            sms: false,
            kakao: false,
          },
        }));
        setOriginalPhone(userData.phone || '');
      } catch (error) {
        console.error('사용자 정보 조회 실패:', error);
      }
    };

    fetchUserInfo();
  }, [token]);

  // 알림 표시
  const showNotificationMessage = (msg) => {
    setNotificationText(msg);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  // -------------------------
  // 일반 input 변경 핸들러
  // -------------------------
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // 휴대폰 번호 변경
  const handlePhoneChange = (e) => {
    const newPhone = e.target.value;
    setFormData((prev) => ({ ...prev, phone: newPhone }));
    if (newPhone !== originalPhone) {
      setIsPhoneChanged(true);
      setIsCodeVerified(false);
    } else {
      setIsPhoneChanged(false);
    }
  };

  // -------------------------
  // (개별) 마케팅 동의
  // -------------------------
  const handleMarketingConsentChange = (channel) => {
    setFormData((prev) => ({
      ...prev,
      marketingConsent: {
        ...prev.marketingConsent,
        [channel]: !prev.marketingConsent[channel],
      },
    }));
  };

  // -------------------------
  // 회원 탈퇴
  // -------------------------
  const handleDeleteAccount = async () => {
    if (!window.confirm('정말로 회원 탈퇴를 진행하시겠습니까?')) return;

    try {
      const response = await axios.delete(
        'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/delete',
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success) {
        showNotificationMessage('회원 탈퇴가 완료되었습니다.');
        dispatch(logoutUser());
        navigate('/');
      } else {
        showNotificationMessage(`회원 탈퇴 실패: ${response.data.message}`);
      }
    } catch (error) {
      console.error('회원 탈퇴 실패:', error);
      showNotificationMessage('회원 탈퇴 중 오류가 발생했습니다.');
    }
  };

  // -------------------------
  // coolsms 전송 준비
  // -------------------------
  const getIsoDate = () => new Date().toISOString();
  const uniqueId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };
  const getSignature = async (apiSecret, msg) => {
    const encoder = new TextEncoder();
    const keyData = encoder.encode(apiSecret);
    const key = await crypto.subtle.importKey(
      'raw',
      keyData,
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign']
    );
    const msgData = encoder.encode(msg);
    const signature = await crypto.subtle.sign('HMAC', key, msgData);
    return Array.from(new Uint8Array(signature))
      .map((b) => ('00' + b.toString(16)).slice(-2))
      .join('');
  };

  // -------------------------
  // SMS 인증 로직
  // -------------------------
  const handleSmsVerification = async () => {
    setIsVerifying(true);
    const phoneNumber = formData.phone;
    const code = Math.floor(100000 + Math.random() * 900000);
    setGeneratedCode(code.toString());

    const messageData = {
      message: {
        to: phoneNumber,
        from: '07080645411',
        text: `We are how2play\n인증 코드: ${code}`,
        type: 'SMS',
      },
    };

    const date = getIsoDate();
    const salt = uniqueId();
    const combinedString = date + salt;

    // 예시 값이므로 본인 인증키로 대체
    const YOUR_API_KEY = 'NCSS5OOW6P8JIUKJ';
    const apiSecret = 'FRAZNM9BHUGLSKFQFQC9TVFQL3KC7TF6';
    const signature = await getSignature(apiSecret, combinedString);

    const headers = {
      Authorization: `HMAC-SHA-256 apiKey=${YOUR_API_KEY}, date=${date}, salt=${salt}, signature=${signature}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(
        'https://api.coolsms.co.kr/messages/v4/send',
        messageData,
        { headers }
      );
      console.log('SMS 전송 성공:', response.data);
      showNotificationMessage('인증 코드가 전송되었습니다. 확인 후 입력해주세요.');
    } catch (error) {
      console.error('SMS 전송 실패:', error);
      showNotificationMessage('SMS 전송에 실패했습니다. 다시 시도해주세요.');
    } finally {
      setIsVerifying(false);
    }
  };

  // 인증번호 확인
  const handleVerifyCode = () => {
    if (verificationCode === generatedCode) {
      setIsCodeVerified(true);
      showNotificationMessage('인증번호가 확인되었습니다.');
    } else {
      showNotificationMessage('인증번호가 일치하지 않습니다.');
      setIsCodeVerified(false);
    }
  };

  // -------------------------
  // 회원정보 수정
  // -------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    // 휴대폰이 바뀌었는데 인증이 안 됐다면 막기
    if (isPhoneChanged && !isCodeVerified) {
      showNotificationMessage('휴대폰 번호가 변경되었습니다. 인증 후 수정 가능합니다.');
      return;
    }

    const updateData = {
      name: formData.name,
      birthdate: formData.birthdate,
      gender: formData.gender,
      phone: formData.phone,
      account_bank: formData.accountBank,
      account_number: formData.accountNumber,
      account_holder: formData.accountHolder,
      // 마케팅 동의 사항
      marketing_consent: {
        email: !!formData.marketingConsent.email,
        sms: !!formData.marketingConsent.sms,
        kakao: !!formData.marketingConsent.kakao,
      },
    };

    try {
      const response = await axios.put(
        'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/update',
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('API 응답:', response);
      // 최신 정보 다시 저장 (토큰 그대로이므로 loginWithToken 호출)
      dispatch(loginWithToken(token));
      showNotificationMessage('회원정보가 성공적으로 수정되었습니다.');

      // 3초 뒤 마이페이지로 이동
      setTimeout(() => {
        navigate('/mypage');
      }, 3000);
    } catch (error) {
      console.error('전체 에러 객체:', error);
      let errorMessage = '회원정보 수정에 실패했습니다.';
      if (error.response) {
        errorMessage += ` (${error.response.status}: ${JSON.stringify(error.response.data)})`;
      } else if (error.request) {
        errorMessage += ' (서버로부터 응답이 없습니다.)';
      } else {
        errorMessage += ` (${error.message})`;
      }
      showNotificationMessage(errorMessage);
    }
  };

  // -------------------------
  // 렌더
  // -------------------------
  return (
    <div className="edit-info-page">
      {showNotification && <div className="notification">{notificationText}</div>}

      {/* Heading row */}
      <div className="heading-row">
        <button className="back-button" onClick={() => navigate('/mypage')}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <h2>회원정보 수정</h2>
      </div>

      <form onSubmit={handleSubmit} className="info-form">
        <div className="edit-section">
          <h3>로그인 정보</h3>

          <div className="input-group">
            <label>아이디</label>
            <input
              type="text"
              name="id"
              value={formData.id}
              onChange={handleInputChange}
              disabled
              className="input-field"
            />
          </div>

          <div className="input-group">
            <label>이름(실명)</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="input-field"
            />
          </div>

          <div className="input-group">
            <label>생년월일</label>
            <input
              type="date"
              name="birthdate"
              value={formData.birthdate}
              onChange={handleInputChange}
              className="input-field"
            />
          </div>

          {/* 성별 선택 */}
          <div className="gender-selection">
            <p className="gender-label">성별 선택</p>
            <div className="gender-buttons">
              <button
                type="button"
                className={`gender-btn ${formData.gender === 'Male' ? 'active' : ''}`}
                onClick={() => setFormData((prev) => ({ ...prev, gender: 'Male' }))}
              >
                남자
              </button>
              <button
                type="button"
                className={`gender-btn ${formData.gender === 'Female' ? 'active' : ''}`}
                onClick={() => setFormData((prev) => ({ ...prev, gender: 'Female' }))}
              >
                여자
              </button>
              <button
                type="button"
                className={`gender-btn ${formData.gender === 'Other' ? 'active' : ''}`}
                onClick={() => setFormData((prev) => ({ ...prev, gender: 'Other' }))}
              >
                기타
              </button>
            </div>
          </div>

          {/* 휴대폰 번호 */}
          <div className="input-group">
            <label>휴대폰 번호</label>
            <div className="phone-row">
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handlePhoneChange}
                className="phone-field"
              />
              {isPhoneChanged && (
                <>
                  {isVerifying ? (
                    <button type="button" className="verify-btn" disabled>
                      인증...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="verify-btn"
                      onClick={handleSmsVerification}
                    >
                      인증
                    </button>
                  )}
                </>
              )}
            </div>
          </div>

          {isPhoneChanged && (
            <div className="verify-row">
              <input
                type="text"
                placeholder="인증번호 입력"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="code-field"
              />
              <button type="button" className="verify-btn" onClick={handleVerifyCode}>
                확인
              </button>
            </div>
          )}
        </div>

        {/* 마케팅 수신 동의 */}
        <div className="marketing-section">
          <h3>마케팅 수신 동의</h3>
          <label className="checkbox-container">
            이메일
            <input
              type="checkbox"
              checked={formData.marketingConsent.email}
              onChange={() => handleMarketingConsentChange('email')}
            />
          </label>
          <label className="checkbox-container">
            문자
            <input
              type="checkbox"
              checked={formData.marketingConsent.sms}
              onChange={() => handleMarketingConsentChange('sms')}
            />
          </label>
          <label className="checkbox-container">
            카카오톡
            <input
              type="checkbox"
              checked={formData.marketingConsent.kakao}
              onChange={() => handleMarketingConsentChange('kakao')}
            />
          </label>
        </div>

        <button type="submit" className="save-btn">
          수정하기
        </button>

        {/* 회원탈퇴 버튼 */}
        <button type="button" className="delete-account-btn" onClick={handleDeleteAccount}>
          회원탈퇴
        </button>
      </form>
    </div>
  );
};

export default EditInfo;
