import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './TicketInfoPage.css';
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';

const TicketInfoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const clubId = location?.state?.clubId;  // ✅ 클럽 ID 가져오기
  const clubName = location?.state?.clubName || '클럽 이름 없음';
  const ticketPrices = location?.state?.ticketPrices || {}; // ✅ 요일별 가격 정보 가져오기
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  
  const showNotificationPopup = (message) => {
    console.log("Notification popup message:", message);
    setNotificationText(message);
  };

  const checkUserEmail = async () => {
    try {
      const response = await fetch("https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/user", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("CapacitorStorage.token")}`,
        },
      });
      if (!response.ok) {
        throw new Error("유저 정보를 불러오지 못했습니다.");
      }
      const userData = await response.json();
      // 예시 조건: 이메일이 "playlist@test.com"이 아니면 false 리턴
      if (userData.email !== "playlist@test.com") {
        showNotificationPopup("현재 티켓 구매가 불가능합니다.");
        return false;
      }
      return true;
    } catch (error) {
      console.error("유저 정보 확인 실패:", error);
      showNotificationPopup("현재 티켓 구매가 불가능합니다.");
      return false;
    }
  };
  const handleProceedToPurchase = async () => {
    
  const canPurchase = await checkUserEmail();
  if (!canPurchase) return;
    navigate('/ticket-purchase', { state: { clubId,  // ✅ 클럽 ID 전달
            clubName,  
            ticketPrices, 
      }, replace: true });
  };

  const handleBack = () => {
    navigate(-1); // 뒤로가기 기능
  };

  return (
    <div className="ticket-info-page">
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}

      {/* 좌측 상단의 뒤로가기 아이콘 */}
      <span className="popup-back-symbol-info" onClick={handleBack}>
        &lt;
      </span>

      {/* 전체 콘텐츠를 감싸는 래퍼 */}
      <div className="content-wrapper">
        {/* 입장권 설명 박스 */}
        <div className="ticket-description">
          <h2 className="ticket-title">{clubName} 프리패스 입장권</h2>
          <p className="ticket-detail">입장권 구매시 클럽 웨이팅(대기) 없이 바로 입장이 가능합니다.</p>
          <p className="ticket-detail">입장권 구매 이후 7일 이내 사용 가능</p>
        </div>

        {/* 불렛 포인트 섹션 */}
        <div className="bullet-points">
          <ul>
            <li>업체 상세페이지에서 운영시간과 유의사항 등을 반드시 확인 후 사용하시기 바랍니다.</li>
            <li>미성년자의 경우 입장 및 쿠폰 사용이 불가할 수 있습니다.</li>
            <li>입장권 이용시간을 꼭 숙지해주시고 사용해주시길 바랍니다.</li>
            <li>입장권은 본인 소지 시에만 사용 가능하며, 타인에게 양도할 수 없습니다.</li>
            <li>입장권은 일회성이며, 재사용이 불가능합니다.</li>
            <li>입장권은 특정 서비스에 한하여 유효합니다. 다른 서비스나 품목에는 사용할 수 없습니다.</li>
          </ul>
        </div>

        {/* 입장권 구매 버튼 */}
        <button className="purchase-button" onClick={handleProceedToPurchase}>
          입장권 구매
        </button>
      </div>
    </div>
  );
};

export default TicketInfoPage;
