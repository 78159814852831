// App.js
import React, { useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from './store';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

import LandingPage from './Pages/LandingPage/LandingPage.jsx';
import LoginPage from './Pages/Login/LoginPage';
import SignupPage from './Pages/SignupPage/SignupPage';
import MainPage from './Pages/Main/MainPage';
import SearchClubsPage from './Pages/Clubs/SearchClubsPage';
import LikesPage from './Pages/Likes/LikesPage';
import MyPage from './Pages/MyPage/MyPage';
import CustomerService from './Pages/MyPage/CustomerService';
import Navbar from './Components/Navbar/Navbar';
import LoginSuccess from './Pages/Login/LoginSuccess';
import FindIdPage from './Pages/FindId/FindIdPage';
import FindPwPage from './Pages/FindPw/FindPwPage';
import ResetPasswordPage from './Pages/FindPw/ResetPasswordPage';
import MapPage from './Pages/MapPage/MapPage';
import EditInfo from './Pages/EditInfo/EditInfo';
import NotificationSettings from './Pages/NotificationSettings/NotificationSettings';
import PhoneVerificationPage from './Pages/Phone/PhoneVerificationPage';
import TicketPurchasePage from './Pages/TicketPurchase/TicketPurchasePage';
import DeepLinkListener from './Components/DeepLinkListener';
import TicketInfoPage from './Pages/TicketInfo/TicketInfoPage';
import PaymentSuccess from "./Pages/Payment/PaymentSuccess";
import PaymentFail from "./Pages/Payment/PaymentFail";
import MyPageV2 from './Pages/MyPagev2/MyPagev2';
import Announcements from './Pages/Announcements/Announcements';
import AdminAnnouncementPage from './Pages/AdminAnnouncement/AdminAnnouncementPage';
import TermsOfService from './Pages/TermsOfService/TermsOfService';
import PrivacyPolicyPage from './Pages/PrivacyPolicy/PrivacyPolicy';
import MarketingConsentPage from './Pages/MarketingConsent/MarketingConsentPage';

import { loginWithToken, restoreAuthState } from "./store/slices/authSlice";
import { registerPushNotifications } from "./services/pushNotifications";

// 네이티브 앱용 보호 라우트 구성
const ProtectedRoute = ({ isAuthenticated, isLoading }) => {
  if (isLoading) return <div>Loading...</div>;
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

const NavbarWrapper = () => {
  const location = useLocation();
  const hiddenRoutes = [
    '/login',
    '/signup',
    '/find-id',
    '/find-pw',
    '/account-info',
    '/map',
    '/profile-management',
    '/edit-info',
    '/notification-settings',
  ];
  return !hiddenRoutes.includes(location.pathname) ? <Navbar /> : null;
};

const AppRoutes = () => {
  const dispatch = useDispatch();
  const { token, isAuthenticated, isGuest, isLoading } = useSelector((state) => state.auth);

  // 앱 초기화 시 restoreAuthState 호출
  useEffect(() => {
    dispatch(restoreAuthState());
  }, [dispatch]);

  // 토큰이 있으면서 인증되지 않았다면 loginWithToken 호출
  useEffect(() => {
    if (token && !isAuthenticated) {
      dispatch(loginWithToken(token));
    }
  }, [token, isAuthenticated, dispatch]);

  return (
    <div className="app-container">
      <Routes>
        {/* 로그인, 회원가입 라우트 */}
        <Route
          path="/login"
          element={(isAuthenticated && !isGuest) ? <Navigate to="/home" /> : <LoginPage />}
        />
        <Route
          path="/signup"
          element={(isAuthenticated && !isGuest) ? <Navigate to="/home" /> : <SignupPage />}
        />
        <Route path="/login-success" element={<LoginSuccess />} />
        <Route path="/phone-verification" element={<PhoneVerificationPage />} />
        <Route path="/find-id" element={<FindIdPage />} />
        <Route path="/find-pw" element={<FindPwPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/edit-info" element={<EditInfo />} />
        <Route path="/notification-settings" element={<NotificationSettings />} />
        <Route path="/home" element={<MainPage />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/marketing-consent" element={<MarketingConsentPage />} />

        {/* 보호된 라우트 */}
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading} />}>
          <Route path="/likes" element={<LikesPage />} />
          <Route path="/mypage" element={<MyPage />} />
          <Route path="/customer-service" element={<CustomerService />} />
          <Route path="/mypage-v2" element={<MyPageV2 />} />
        </Route>

        {/* 기타 앱 라우트 */}
        <Route path="/clubs" element={<SearchClubsPage />} />
        <Route path="/ticket-info" element={<TicketInfoPage />} />
        <Route path="/ticket-purchase" element={<TicketPurchasePage />} />
        <Route path="/success" element={<PaymentSuccess />} />
        <Route path="/fail" element={<PaymentFail />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route path="/admin-announcements" element={<AdminAnnouncementPage />} />
        <Route path="/landingPage" element={<LandingPage />} />

        {/* 기본 라우트 */}
        <Route
          path="/"
          element={<Navigate to={(isAuthenticated && !isGuest) ? "/home" : "/login"} />}
        />
        <Route
          path="*"
          element={<Navigate to={(isAuthenticated && !isGuest) ? "/home" : "/login"} />}
        />
      </Routes>
      <NavbarWrapper />
      <DeepLinkListener />
    </div>
  );
};

// 네이티브 앱에서는 전체 앱 라우트를 사용 (푸시 알림 등 포함)
const NativeApp = () => {
  useEffect(() => {
    registerPushNotifications();
  }, []);

  return <AppRoutes />;
};

// 웹 접속 시에는 앱 라우트는 차단하고, 오직 /privacy, /terms, /marketing-consent 만 접근 가능하도록 구성
const WebRoutes = () => {
  return (
    <div className="web-container">
      <Routes>
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/marketing-consent" element={<MarketingConsentPage />} />
        {/* 기타 경로는 기본적으로 LandingPage로 리다이렉트 */}
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </div>
  );
};

const App = () => {
  const forceNative = process.env.REACT_APP_FORCE_NATIVE === 'true';
  const platform = Capacitor.getPlatform();
  // 네이티브 앱이면 전체 앱 라우트를, 웹이면 제한된 라우트만 제공
  const useNativeApp = forceNative || platform !== 'web';

  return (
    <Provider store={store}>
      <Router>
        {useNativeApp ? <NativeApp /> : <WebRoutes />}
      </Router>
    </Provider>
  );
};

export default App;
