import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './NotificationSettings.css';
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';


const NotificationSettings = () => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState('');

  // ✅ 알림 표시 함수
  const showNotificationPopup = (message) => {
    setNotificationText(message);
    
  };

  return (
    <div className="notification-settings">
      <button className="back-button" onClick={() => navigate(-1)}>
        &larr;
      </button>
      <h2>알림 설정</h2>
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText('')}
        />
      )}
      <div className="notice-section">
        <h3>How2Play</h3>
        <p>기기 설정에 따라 일부 알림을 받을 수 없습니다. 알림 설정을 확인해 주세요.</p>
      </div>

      <div className="notification-option">
        <label>클럽 정보 알림</label>
        <input type="checkbox" />
      </div>

      <div className="notification-option">
        <label>이벤트 및 할인 정보 알림</label>
        <input type="checkbox" />
      </div>

      <div className="notification-option">
        <label>야간 클럽 정보 수신</label>
        <input type="checkbox" />
      </div>

      <button 
        className="save-button" 
        onClick={() => showNotificationPopup('알림 설정이 저장되었습니다.')}
      >
        저장하기
      </button>
    </div>
  );
};

export default NotificationSettings;
