// src/store/slices/clubsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async Thunks
export const fetchAllClubs = createAsyncThunk('clubs/fetchAllClubs', async () => {
  const response = await axios.get('https://fastapi-backend-657266059621.asia-northeast1.run.app/api/clubs/get-clubs');
  return response.data;
});

export const fetchLikedClubs = createAsyncThunk('clubs/fetchLikedClubs', async (userId) => {
  const response = await axios.get(`https://fastapi-backend-657266059621.asia-northeast1.run.app/api/users/${userId}/liked-clubs`);
  return response.data.likedClubs || [];
});

export const toggleLikeClub = createAsyncThunk(
  'clubs/toggleLikeClub',
  async ({ userId, clubId, isLiked }, { rejectWithValue }) => {
    try {
      const method = isLiked ? 'DELETE' : 'POST';
      await axios({
        url: `https://fastapi-backend-657266059621.asia-northeast1.run.app/api/users/${userId}/like-club/${clubId}`,
        method,
        headers: { 'Content-Type': 'application/json' },
      });
      return clubId; // Return the updated club ID
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to toggle like');
    }
  }
);

// Fetch match percentages for clubs
export const fetchMatchPercentages = createAsyncThunk(
  'clubs/fetchMatchPercentages',
  async ({ userId, playlistId }, { getState }) => {
    try {
      const response = await axios.get(
        `https://fastapi-backend-657266059621.asia-northeast1.run.app/api/clubs/compare/${userId}/${playlistId}`
      );

      // Map match percentages to clubs
      const state = getState().clubs;
      const allClubs = state.allClubs;

      const updatedClubs = allClubs.map((club) => {
        const match = response.data.matches.find((item) => item.club_id === club.id);
        return {
          ...club,
          matchPercent: match ? match.matchPercent : 0,
        };
      });

      // Save in localStorage for persistence
      localStorage.setItem('matchPercentages', JSON.stringify(updatedClubs));
      return updatedClubs;
    } catch (error) {
      console.error('Error fetching match percentages:', error);
      return [];
    }
  }
);

// Initial State
const initialState = {
  allClubs: [],
  likedClubs: [],
  matchPercentages: [], // Store match percentages
  isLoading: false,
  error: null,
};

// Slice
const clubsSlice = createSlice({
  name: 'clubs',
  initialState,
  reducers: {
    loadMatchPercentagesFromLocalStorage(state) {
      const savedMatchPercentages = localStorage.getItem('matchPercentages');
      if (savedMatchPercentages) {
        state.matchPercentages = JSON.parse(savedMatchPercentages);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClubs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAllClubs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allClubs = action.payload;
      })
      .addCase(fetchAllClubs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLikedClubs.fulfilled, (state, action) => {
        state.likedClubs = action.payload;
      })
      .addCase(toggleLikeClub.fulfilled, (state, action) => {
        const clubId = action.payload;
        if (state.likedClubs.includes(clubId)) {
          state.likedClubs = state.likedClubs.filter((id) => id !== clubId);
        } else {
          state.likedClubs.push(clubId);
        }
      })
      .addCase(fetchMatchPercentages.fulfilled, (state, action) => {
        state.matchPercentages = action.payload;
      });
  },
});

// Export actions
export const { loadMatchPercentagesFromLocalStorage } = clubsSlice.actions;

// Export reducer
export default clubsSlice.reducer;
