// LoginSuccess.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginWithToken } from '../../store/slices/authSlice';
import axios from 'axios';

const LoginSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const provider = params.get('provider');
    const code = params.get('code');
    if (!provider || !code) {
      navigate('/login', { replace: true });
      return;
    }
    
    // 각 provider별 백엔드 엔드포인트 매핑
    const endpointMapping = {
      spotify: 'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/callback/spotify',
      google: 'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/callback/google',
      kakao: 'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/callback/kakao',
      apple: 'https://fastapi-backend-657266059621.asia-northeast1.run.app/api/auth/callback/apple',
      // 필요한 다른 provider도 추가 가능
    };

    const endpoint = endpointMapping[provider.toLowerCase()];
    if (!endpoint) {
      navigate('/login', { replace: true });
      return;
    }

    const exchangeCodeForToken = async () => {
      try {
        const response = await axios.post(endpoint, { code });
        const { access_token } = response.data;
        await dispatch(loginWithToken(access_token)).unwrap();
        setProcessing(false);
        navigate('/home', { replace: true });
      } catch (error) {
        console.error('Login failed:', error);
        setProcessing(false);
        navigate('/login', { replace: true });
      }
    };

    exchangeCodeForToken();
  }, [location, dispatch, navigate]);

  return <div>{processing ? 'Logging in...' : null}</div>;
};

export default LoginSuccess;
