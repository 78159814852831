import React, { useState } from "react";
import "./CustomerService.css";
import { useNavigate } from "react-router-dom";
import NotificationPopup from "../../Components/NotificationPopup/NotificationPopup";
import Footer from "../../Components/Footer/Footer";

const CustomerService = () => {
  const [inquiryType, setInquiryType] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [status, setStatus] = useState("");
  const [notificationText, setNotificationText] = useState("");

  const navigate = useNavigate();
  const showNotificationPopup = (msg) => {
    setNotificationText(msg);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!agreed) {
      showNotificationPopup("개인정보 처리에 동의하셔야 문의를 전송할 수 있습니다.");
      return;
    }

    setStatus("전송 중...");

    try {
      const response = await fetch(process.env.REACT_APP_FORMSPREE_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          inquiryType,
          email,
          message,
        }),
      });

      if (response.ok) {
        setStatus("문의가 성공적으로 전송되었습니다!");
        // Reset form
        setInquiryType("");
        setEmail("");
        setMessage("");
        setAgreed(false);

        // Navigate to /mypage after 2 seconds
        setTimeout(() => {
          navigate("/mypage");
        }, 2000);
      } else {
        setStatus("문의 전송 중 오류가 발생했습니다.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setStatus("문의 전송 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="customer-service-container">
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}
      <h2 className="customer-service-title">1:1 문의</h2>
      <p className="customer-service-subtitle">
        문의 유형을 선택하고, 자세한 내용을 입력해 주세요.
      </p>

      <form className="customer-service-form" onSubmit={handleSubmit}>
        <label htmlFor="inquiryType" className="customer-service-label">
          문의 유형
        </label>
        <select
          id="inquiryType"
          className="customer-service-select"
          value={inquiryType}
          onChange={(e) => setInquiryType(e.target.value)}
          required
        >
          <option value="" disabled>
            질문 유형을 선택해주세요.
          </option>
          <option value="이용 문의">이용 문의</option>
          <option value="오류 신고">오류 신고</option>
          <option value="결제 관련">결제 관련</option>
          <option value="서비스 제안">서비스 제안</option>
          <option value="기타">기타</option>
        </select>

        <label htmlFor="email" className="customer-service-label">
          이메일
        </label>
        <input
          type="email"
          id="email"
          className="customer-service-input"
          placeholder="example@email.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label htmlFor="message" className="customer-service-label">
          내용
        </label>
        <textarea
          id="message"
          className="customer-service-textarea"
          placeholder="문의 내용을 입력해 주세요."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={5}
          required
        />

        <div className="customer-service-agreement">
          <input
            type="checkbox"
            id="agree"
            checked={agreed}
            onChange={() => setAgreed(!agreed)}
          />
          <label htmlFor="agree" className="customer-service-agreement-label">
            이메일 사용을 위한 개인정보 처리에 동의합니다.
          </label>
        </div>

        <button
          type="submit"
          className="customer-service-submit-btn"
          disabled={!agreed}
        >
          접수하기
        </button>

        {status && <p className="customer-service-status">{status}</p>}
      </form>
      <Footer />
    </div>
  );
};

export default CustomerService;
