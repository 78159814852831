import React, { useEffect, useRef } from 'react';
import './ClubCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faSolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';

const ClubCard = ({ club, isLiked, onLikeClick, showLikeButton = true, onCardClick, isSelected }) => {
  const progressCircleRef = useRef(null);
  const navigate = useNavigate();

  // Calculate the main image: try to find an image where is_main is true; otherwise, use the first available.
  const mainImage =
    club.images && club.images.length > 0
      ? (club.images.find((img) => img.is_main) || club.images[0]).image_url
      : "https://mblogthumb-phinf.pstatic.net/MjAxOTA3MjBfMjc1/MDAxNTYzNjEzNTk2OTMz.pF2Ln_AFhIP7Gxzy6Ci7Ff9qLjmJURDsI_YNyJev8wEg.UVHaASImxCvS1jhj84oQJnW9lVxpPRdEnKAXxCvchaog.JPEG.kkkkk6846/SE-c26fd167-023f-4c53-80fe-02902b704d5b.jpg?type=w800";

  useEffect(() => {
    const circle = progressCircleRef.current;
    if (circle) {
      const radius = circle.r.baseVal.value;
      const circumference = 2 * Math.PI * radius;
      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset = `${circumference}`;

      const offset = circumference - (club.matchPercent / 100) * circumference;
      circle.style.strokeDashoffset = offset;
    }
  }, [club.matchPercent]);

  const handleTicketPurchase = (e) => {
    e.stopPropagation();
    navigate('/ticket-info', { 
      state: {  
        clubId: club.id,  
        clubName: club.name,  
        ticketPrices: club.ticket_prices, 
      } 
    });
  };

  return (
    <div
      className={`club-card-component ${isSelected ? 'selected' : ''}`}
      onClick={() => onCardClick(club)}
      id={`club-${club.id}`}
    >
      <div className="club-card-image-container">
        <img
          src={mainImage}
          alt={`${club.name} 이미지`}
          className="club-card-image"
        />
        {showLikeButton && (
          <button
            className="club-card-like-button"
            onClick={(e) => {
              e.stopPropagation();
              onLikeClick(club);
            }}
          >
            <FontAwesomeIcon icon={isLiked ? faSolidHeart : faRegularHeart} className="fa-icon-heart" />
          </button>
        )}
      </div>

      <div className="club-card-content">
        <h3 className="club-card-name">{club.name}</h3>
        <h3 className="club-card-genre">[{club.genres}]</h3>

        <button className="club-card-ticket-button" onClick={handleTicketPurchase}>
          입장권 구매
        </button>

        <div className="match-progress-container">
          <svg className="progress-circle" viewBox="0 0 36 36">
            <circle className="progress-bg" cx="18" cy="18" r="15.9155" />
            <circle ref={progressCircleRef} className="progress" cx="18" cy="18" r="15.9155" />
            <text x="18" y="20.35" className="progress-text">
              {club.matchPercent}%
            </text>
          </svg>
          <p className="match-label">매치 퍼센트</p>
        </div>
        <p className="club-card-like-count">{club.like_count}명이 이 클럽을 찜했어요</p>
      </div>
    </div>
  );
};

export default ClubCard;
