import React from 'react';
import { useNavigate } from 'react-router-dom';

import './PrivacyPolicy.css';

const PrivacyPolicyPage = (onClose = null) => {
  const navigate = useNavigate();

  const handleClose = () => {
    
    if (typeof onClose === 'function') {
      onClose();
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="privacy-policy-page">
            <button className="close-button" onClick={handleClose}>
        &times;
      </button>
      <h1 className="policy-title">개인정보처리방침</h1>
      
      <section className="policy-section">
        <h2>제1조 (총칙)</h2>
        <p>
          장이신(이하 “회사”)은 이용자의 개인정보를 중요하게 생각하며, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 “정보통신망법”)과 개인정보 보호법 등 관련 법령상의 개인정보 보호 규정을 준수하고 있습니다.
          <br /><br />
          how2play.club
          <br /><br />
          회사는 이러한 법령을 바탕으로 이용자의 개인정보를 적법하게 처리하고 안전하게 관리하기 위해 최선을 다하고 있으며, 본 개인정보처리방침을 통하여 이용자가 제공한 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보 보호를 위해 어떠한 조치가 취해지고 있는지 안내해 드립니다.
          <br /><br />
          본 개인정보처리방침은 관련 법령 및 내부 운영 방침에 따라 수시로 변경될 수 있으며, 변경되는 경우 앱 내 공지사항 등을 통해 공지할 것입니다.
        </p>
      </section>

      <section className="policy-section">
        <h2>개인정보의 처리 목적</h2>
        <p>
          회사가 수집한 개인정보는 주로 다음과 같은 목적으로 사용됩니다.
          <br /><br />
          <strong>서비스 제공 및 운영:</strong> 앱 회원 가입 및 유지관리, 콘텐츠 제공, 클럽 예약/티켓 구매 및 요금 결제, 물품 배송, 본인 인증 및 연령 확인, 고객 문의 응대 등 서비스 제공에 관한 계약 이행 및 운영.
          <br /><br />
          <strong>회원 관리:</strong> 회원제 서비스 이용에 따른 신원 확인, 본인 식별, 불량 이용자 방지, 가입 의사 확인, 고지사항 전달, 분쟁 해결 기록 보존 등.
          <br /><br />
          <strong>신규 서비스 개발 및 마케팅/광고 활용:</strong> 신규 기능 개발, 맞춤형 서비스 제공, 접속 빈도 분석, 서비스 이용 통계 등 내부 연구자료로 활용되며, 이벤트 안내나 광고성 정보 전송(푸시 알림, 뉴스레터 등) 등 마케팅 목적으로도 사용됩니다.
          <br /><br />
          단, 회원의 개인정보는 광고를 의뢰한 제3자에게 제공되지 않습니다.
        </p>
      </section>

      <section className="policy-section">
        <h2>수집하는 개인정보의 항목 및 수집 방법</h2>
        <p>
          회사는 서비스 제공을 위해 필요한 최소한의 개인정보를 수집합니다.
          <br /><br />
          <strong>수집 항목 및 방법:</strong>
          <br />
          - <em>회원 가입 시:</em> 이름(또는 닉네임), 아이디, 비밀번호, 생년월일(또는 연령대), 성별, 휴대전화번호, 이메일 주소 등<br />
          - <em>추가 선택 항목:</em> 프로필 사진, 관심사, 클럽 선호 장르 등 (선택 사항이며, 제공하지 않아도 서비스 이용에는 제한 없음)<br />
          - <em>자동 수집 항목:</em> 기기정보(디바이스 ID, OS, 앱 버전), 접속 로그(일시, IP, 쿠키 등), 이용 기록 등<br />
          - <em>제휴 플랫폼:</em> 소셜 미디어 로그인을 통한 정보 제공(이용자 동의 하에)
        </p>
      </section>

      <section className="policy-section">
        <h2>개인정보의 처리 및 보유 기간</h2>
        <p>
          회사는 수집한 개인정보를 이용 목적이 달성될 때까지 보유하며, 회원 탈퇴 또는 동의 철회 시 즉시 파기합니다.
          <br /><br />
          단, 관계 법령에서 정하는 경우에는 해당 기간 동안 안전하게 보관 후 파기합니다.
          <br /><br />
          <strong>보존 기간 :</strong><br />
          - 계약 또는 청약철회 등에 관한 기록: 5년<br />
          - 대금 결제 및 재화 등의 공급에 관한 기록: 5년<br />
          - 전자금융 거래에 관한 기록: 5년<br />
          - 소비자 불만 및 분쟁처리 기록: 3년<br />
          - 웹사이트 방문 기록: 3개월<br />
          ※ 보존 기간 종료 후에는 지체 없이 파기합니다.
        </p>
      </section>

      <section className="policy-section">
        <h2>개인정보의 수집 및 이용에 대한 동의</h2>
        <p>
          회사는 이용자로부터 개인정보 수집·이용에 대해 사전 동의를 받고 있습니다.
          <br /><br />
          회원가입 시 동의란에 체크하면 필수 개인정보 제공에 동의한 것으로 간주되며, 선택 항목에 대한 동의는 거부하더라도 서비스 이용에 제한이 없습니다.
          <br /><br />
          이용자는 언제든지 동의를 철회할 수 있으며, 이 경우 회사는 관련 법령에 따라 개인정보를 복구 불가능한 방법으로 파기합니다.
        </p>
      </section>

      <section className="policy-section">
        <h2>개인정보의 제3자 제공</h2>
        <p>
          회사는 개인정보를 “개인정보의 처리 목적”에서 고지한 범위 내에서만 사용하며, 이용자의 사전 동의 없이 제3자에게 제공하지 않습니다.
          <br /><br />
          단, 이용자가 사전에 동의한 경우 또는 법령에 의해 제공 의무가 발생하는 경우, 결제나 배송 등 서비스 이행을 위해 필요한 경우에 한해 제공할 수 있습니다.
        </p>
      </section>

      <section className="policy-section">
        <h2>개인정보처리의 위탁</h2>
        <p>
          회사는 결제 대행, 서버 운영, 데이터 분석 등 서비스 제공에 필요한 업무를 외부 업체에 위탁할 수 있으며, 위탁 시 관련 법령에 따라 이용자에게 고지하고 동의를 받습니다.
        </p>
      </section>

      <section className="policy-section">
        <h2>이용자 및 법정대리인의 권리와 행사방법</h2>
        <p>
          이용자(정보주체)는 언제든지 자신의 개인정보 열람, 정정, 삭제, 처리정지, 동의 철회 등을 요청할 수 있습니다.
          <br /><br />
          요청은 앱 내 설정 또는 고객센터를 통해 가능하며, 회사는 관련 법령에 따라 신속하게 처리하고 결과를 통지합니다.
          <br /><br />
          만 14세 미만 아동의 경우, 법정대리인은 아동의 개인정보에 대해 동일한 권리를 행사할 수 있습니다.
        </p>
      </section>

      <section className="policy-section">
        <h2>개인정보의 안전성 확보 조치</h2>
        <p>
          회사는 개인정보 보호를 위해 관리적, 기술적, 물리적 조치를 시행하고 있습니다.
          <br /><br />
          - 관리적 조치: 개인정보 보호책임자 지정, 내부 관리계획 수립 및 직원 교육<br />
          - 기술적 조치: 암호화 저장, 방화벽 및 침입 탐지 시스템 도입, 접속기록 보관 등<br />
          - 물리적 조치: 중요 시설 출입통제, 자료 보관 및 폐기 시 안전한 절차 준수
        </p>
      </section>

      <section className="policy-section">
        <h2>개인정보 보호책임자 및 문의처</h2>
        <p>
          회사는 개인정보 보호와 관련된 문의, 불만, 침해 신고를 신속하게 처리하기 위해 개인정보 보호책임자를 지정하고 있습니다.
          <br /><br />
          개인정보 보호책임자: 장이신 (대표)
          <br />
          연락처: [07080645411]
          <br />
          이메일: [how2play.business@gmail.com]
          <br /><br />
          추가 문의는 아래 기관에 연락할 수 있습니다.
          <br />
          - 개인정보분쟁조정위원회: 전화 1833-6972, 홈페이지: www.kopico.go.kr<br />
          - 개인정보침해신고센터: 전화 118, 홈페이지: privacy.kisa.or.kr<br />
          - 대검찰청 사이버범죄수사과: 전화 1301, 홈페이지: www.spo.go.kr<br />
          - 경찰청 사이버안전국: 전화 182, 홈페이지: ecrm.cyber.go.kr
        </p>
      </section>

      <section className="policy-section">
        <h2>개인정보처리방침의 변경</h2>
        <p>
          본 개인정보처리방침은 법령이나 내부 정책 변경, 신규 서비스 출시 등에 따라 수정될 수 있으며, 변경 시 앱 내 공지사항 등을 통해 사전에 공지합니다.
          <br /><br />
          변경된 개인정보처리방침은 이용자가 쉽게 열람할 수 있도록 제공되며, 중대한 변경 시 별도 동의 절차를 거칠 수 있습니다.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;
