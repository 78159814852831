import React, { useState, useEffect } from 'react';
import './MainPage.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify, faYoutube } from '@fortawesome/free-brands-svg-icons';
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';


const MainPage = () => {
  const [playlistUrl, setPlaylistUrl] = useState('');
  const [isSaving, setIsSaving] = useState(false); // 저장 중 상태
  const [recentPlaylists, setRecentPlaylists] = useState([]); // 최근 플레이리스트 상태
  const [notificationText, setNotificationText] = useState("");

  const navigate = useNavigate();
  const { user, isLoading: authLoading, isAuthenticated } = useSelector((state) => state.auth);
  
  const showNotificationPopup = (message) => {
    setNotificationText(message);
    
  };

  const fetchRecentPlaylists = async (userId) => {
    try {
      const response = await fetch(`https://fastapi-backend-657266059621.asia-northeast1.run.app/api/spotify/users/${userId}/playlists`);
      if (!response.ok) {
        throw new Error('플레이리스트를 가져오는 데 실패했습니다.');
      }
      const data = await response.json();
      setRecentPlaylists(data);
    } catch (error) {
      //console.error('플레이리스트 가져오기 오류:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user?.id) {
      fetchRecentPlaylists(user.id);
    }
  }, [isAuthenticated, user]);

  const handlePlayButtonClick = async () => {
    if (!playlistUrl) {
      //alert('플레이리스트 링크를 입력하세요!');
      showNotificationPopup('플레이리스트 링크를 입력하세요!');
      return;
    }

    try {
      new URL(playlistUrl);
    } catch (_) {
      //alert('유효한 URL을 입력하세요!');
      showNotificationPopup('유효한 URL을 입력하세요!');
      return;
    }

    let platform;
    if (playlistUrl.includes('spotify')) {
      platform = 'spotify';
    } else if (playlistUrl.includes('youtube')) {
      platform = 'ytmusic';

    } else {
      //alert('지원되지 않는 링크입니다! Spotify 또는 YouTube 링크를 입력하세요.');
      showNotificationPopup('지원되지 않는 링크입니다! Spotify 또는 YouTube 링크를 입력하세요.');
      return;
    }

    setIsSaving(true);

    try {
      const userId = isAuthenticated ? user.id : 'guest';
      const url = new URL(`https://fastapi-backend-657266059621.asia-northeast1.run.app/api/${platform}/save-playlist`);
      url.searchParams.append('playlist_url', playlistUrl);
      url.searchParams.append('user_id', userId);

      const response = await fetch(url, { method: 'POST' });
      if (!response.ok) throw new Error('플레이리스트 저장 중 오류가 발생했습니다.');

      const data = await response.json();
      const { playlist_id } = data;

      if (isAuthenticated && user?.id) {
        fetchRecentPlaylists(user.id);
      }

      navigate(`/clubs?playlist_id=${playlist_id}`);
    } catch (error) {
      console.error('저장 오류:', error);
      //alert('플레이리스트 저장에 실패했습니다.');
      showNotificationPopup('플레이리스트 저장에 실패했습니다.');
      
    } finally {
      setIsSaving(false);
    }
  };

  const handleRecentPlaylistClick = (playlistId) => {
    navigate(`/clubs?playlist_id=${playlistId}`);
  };

  const getPlaylistIcon = (link) => {
    if (typeof link !== 'string') return faSpotify;
    if (link.includes('spotify')) return faSpotify;
    if (link.includes('youtu')) return faYoutube;
    return faSpotify;
  };
  const getDisplayName = (name) => {
    if (!name) return "";
    // 이름이 "user_"로 시작하고, 복잡한 형태일 경우 간단하게 표시
    if (name.startsWith("user_") && name.includes(".")) {
      return "Apple User";
    }
    return name;
  };

  if (authLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="main-page">
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}
      <div className="main-logo-container">
        <h1 className="main-logo-text">HOW2PLAY</h1>
      </div>
      <div className="main-content">
        <h2 className="main-title">어떤 클럽에 방문하고 싶으신가요?</h2>
        <p className="main-subtitle">Find your best club which fits you</p>

        <div className="input-container">
          <input
            type="text"
            placeholder="플레이리스트 링크를 붙여 넣어 보세요!"
            className="playlist-input"
            value={playlistUrl}
            onChange={(e) => setPlaylistUrl(e.target.value)}
          />
          <button
            className="play-button"
            onClick={handlePlayButtonClick}
            disabled={isSaving}
          >
             {isSaving ? (
                <img src="/How2Play_mainLogo128.png" alt="Loading" className="button-logo-spin" />
              ) : (
                'Play'
              )}
          </button>
        </div>
      </div>

      <div className="playlist-section">
        <h2 className="playlist-section-title">
          {user?.name && user.name !== 'Guest'
            ? `${getDisplayName(user.name)}님의 이전 플레이리스트`
            : '로그인 후 이전 플레이리스트를 불러오세요!'}
        </h2>

        <div className="playlist-box-container">
          {recentPlaylists.map((playlist) => (
            <div
              key={playlist.id}
              className="playlist-box"
              onClick={() => handleRecentPlaylistClick(playlist.id)}
              style={{ cursor: 'pointer' }}
            >
              <div className="playlist-thumbnail">
                {playlist.image_url ? (
                  <img
                    src={playlist.image_url}
                    alt={playlist.title ? `${playlist.title} thumbnail` : '플레이리스트 이미지'}
                    className="playlist-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/path/to/default/image.jpg';
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={getPlaylistIcon(playlist.link)}
                    className={`playlist-icon ${
                      typeof playlist.link === 'string' && playlist.link.includes('spotify')
                        ? 'spotify-icon'
                        : 'youtube-icon'
                    }`}
                  />
                )}
              </div>
              <p className="playlist-name">{playlist.title || '플레이리스트 이름 없음'}</p>
            </div>
          ))}
        </div>
      </div>
      <img
          src="/How2Play_mainLogo512.png"
          alt="How2Play Logo"
          className="main-logo"
        />
    </div>
  );
};

export default MainPage;
