import React from 'react';
import { useNavigate } from 'react-router-dom';

import './MarketingConsentPage.css';

const MarketingConsentPage = ({ onClose = null }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="marketing-consent-page">
       <button className="close-button" onClick={handleClose}>
        &times;
      </button>
      <h1 className="marketing-title">마케팅 정보 수신 동의</h1>

      <section className="marketing-section">
        <h2>목적 및 내용</h2>
        <p>
          회사는 이벤트/프로모션/신규 서비스 안내 등을 목적으로 회원에게 이메일, 문자(SMS, LMS), 푸시알림 등을 발송할 수 있습니다. 
          <br /><br />
          <strong>수집·이용 항목:</strong> 이름, 이메일 주소, 휴대폰 번호 등
          <br />
          <strong>이용 목적:</strong> 이벤트 및 혜택 안내, 신규 서비스 홍보 등 광고성 정보 제공
          <br />
          <strong>보유·이용 기간:</strong> 동의 철회 또는 회원 탈퇴 시까지 (단, 법령상 의무 보관기간이 있는 경우 해당 기간 보관)
        </p>
      </section>

      <section className="marketing-section">
        <h2>수신 동의 및 철회</h2>
        <p>
          회원은 마케팅 정보 수신에 동의하지 않아도 서비스 이용이 가능합니다. 
          <br />
          동의 후에도 언제든지 <em>이메일 하단의 ‘수신 거부’ 버튼</em>이나 <em>고객센터 연락</em> 등을 통해 광고성 정보 수신을 거부할 수 있습니다.
          <br /><br />
          또한, 광고성 정보 전송 시 관련 법령에 따라 발신자명, 연락처, 수신 거부 방법 등을 명시합니다.
        </p>
      </section>

      <section className="marketing-section">
        <h2>유의사항</h2>
        <p>
          - 마케팅 정보 수신 동의는 선택 사항이므로, 거부하더라도 서비스 이용에 제한이 없습니다.
          <br />
          - 동의 철회 시, 회사는 즉시(또는 법령이 허용하는 기간 내) 광고성 정보 발송을 중단하며, 이미 발송된 메시지는 회수나 취소가 불가능할 수 있습니다.
          <br />
          - 필수 안내 사항(예: 결제 내역, 이용약관 개정 등)은 동의 여부와 관계없이 발송될 수 있습니다.
        </p>
      </section>
    </div>
  );
};

export default MarketingConsentPage;
