import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import './SearchClubsPage.css';
import Navbar from '../../Components/Navbar/Navbar';
import ClubCard from '../../Components/ClubCard/ClubCard';
import ClubDetailPopup from '../../Components/ClubDetailPopup/ClubDetailPopup';
import MapPage from '../MapPage/MapPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchAllClubs,
  fetchLikedClubs,
  toggleLikeClub,
  fetchMatchPercentages,
} from '../../store/slices/clubsSlice';
import SearchBar from '../../Components/SearchBar/SearchBar';
import NotificationPopup from '../../Components/NotificationPopup/NotificationPopup';

const SearchClubsPage = () => {
  // 상태 변수들
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showGenrePopup, setShowGenrePopup] = useState(false);
  const [showClubDetails, setShowClubDetails] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [notificationText, setNotificationText] = useState("");

  // 리스트의 높이 (기본 40vh)
  const [listHeight, setListHeight] = useState(40);
  
  // 드래그 시작 위치, 시작 높이, 드래그중 여부 ref
  const startYRef = useRef(0);
  const startHeightRef = useRef(0);
  const isDraggingRef = useRef(false);

  // 스크롤 컨테이너 ref
  const cardsContainerRef = useRef(null);

  // "내 주변" 모드
  const [isNearbyActive, setIsNearbyActive] = useState(false);
  // 검색어 상태
  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const { allClubs, likedClubs, matchPercentages, isLoading } = useSelector(
    (state) => state.clubs
  );

  // 알림 팝업
  const showNotificationPopup = (message) => {
    setNotificationText(message);
  };

  // URL에서 playlist_id 확인
  const searchParams = new URLSearchParams(window.location.search);
  const playlistId = searchParams.get('playlist_id');

  // 모든 클럽 불러오기
  useEffect(() => {
    dispatch(fetchAllClubs())
      .unwrap()
      .then(() => {
        if (isAuthenticated && user) {
          dispatch(fetchLikedClubs(user.id));
        }
      });
  }, [dispatch, isAuthenticated, user]);

  // matchPercentages 불러오기
  useEffect(() => {
    if (playlistId) {
      const finalUserId = isAuthenticated && user?.id ? user.id : 'guest';
      dispatch(fetchMatchPercentages({ userId: finalUserId, playlistId }));
    }
  }, [dispatch, playlistId, isAuthenticated, user]);

  // 클럽 데이터 정리 (matchPercent 내림차순 정렬)
  const clubsData = useMemo(() => {
    const baseData =
      matchPercentages && matchPercentages.length > 0
        ? matchPercentages
        : allClubs;
    return [...baseData].sort(
      (a, b) => (b.matchPercent ?? 0) - (a.matchPercent ?? 0)
    );
  }, [matchPercentages, allClubs]);

  // 지역 목록
  const locations = useMemo(
    () => [...new Set(clubsData.map((club) => club.location))],
    [clubsData]
  );

  // 장르 목록
  const genres = useMemo(() => {
    const allGenres = clubsData.flatMap((club) =>
      club.genres.split(',').map((g) => g.trim())
    );
    return [...new Set(allGenres)];
  }, [clubsData]);

  // 필터링된 클럽
  const filteredClubs = useMemo(() => {
    return clubsData.filter((club) => {
      const matchesSearch = club.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesLocation = selectedLocation
        ? club.location === selectedLocation
        : true;
      const matchesGenre = selectedGenre
        ? club.genres.split(',').map((g) => g.trim()).includes(selectedGenre)
        : true;
      return matchesSearch && matchesLocation && matchesGenre;
    });
  }, [clubsData, searchQuery, selectedLocation, selectedGenre]);

  // 드롭다운 토글
  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  // 지역 선택
  const handleLocationClick = useCallback((location) => {
    setSelectedLocation((prev) => (prev === location ? null : location));
    setIsDropdownOpen(false);
    setIsNearbyActive(false);
  }, []);

  // 장르 선택
  const handleGenreClick = useCallback((genre) => {
    setSelectedGenre((prev) => (prev === genre ? null : genre));
    setShowGenrePopup(false);
    setIsNearbyActive(false);
  }, []);

  // "내 주변" 클릭
  const handleNearbyClick = useCallback(() => {
    setIsNearbyActive((prev) => !prev);
  }, []);

  // 지도에서 클럽 선택 시
  const handleSelectClubFromMap = useCallback((club) => {
    setSelectedClub(club);
    const clubElement = document.getElementById(`club-${club.id}`);
    if (clubElement) {
      clubElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      clubElement.classList.add('highlight');
      setTimeout(() => {
        clubElement.classList.remove('highlight');
      }, 2000);
    }
  }, []);

  // 클럽 상세 열기
  const handleOpenClubDetails = useCallback((club) => {
    setSelectedClub(club);
    setShowClubDetails(true);
  }, []);

  // 클럽 상세 닫기
  const handleClosePopup = useCallback(() => {
    setShowClubDetails(false);
    setSelectedClub(null);
  }, []);

  // 좋아요 토글
  const handleLikeClick = useCallback(
    (club) => {
      if (!user || !isAuthenticated) return;
      const isLiked = likedClubs.includes(club.id);
      dispatch(toggleLikeClub({ userId: user.id, clubId: club.id, isLiked }))
        .unwrap()
        .then(() => {
          if (!isLiked) {
            showNotificationPopup("즐겨찾기에 추가되었습니다");
          }
        })
        .catch((error) => console.error('Error toggling like:', error));
    },
    [dispatch, user, isAuthenticated, likedClubs]
  );

  // 검색어 변경
  const handleSearchChange = useCallback((value) => {
    setSearchQuery(value);
  }, []);

  // =========================================================================
  // = 드래그 핸들 (조건 없이 시트 높이 조절 가능)
  // =========================================================================
  const handleTouchStartHandle = useCallback((e) => {
    startYRef.current = e.touches[0].clientY;
    startHeightRef.current = listHeight;
    isDraggingRef.current = true;
  }, [listHeight]);

  const handleTouchMoveHandle = useCallback((e) => {
    if (!isDraggingRef.current) return;
    const currentY = e.touches[0].clientY;
    const deltaY = startYRef.current - currentY;

    // 위로 드래그(deltaY > 0), 아래로 드래그(deltaY < 0)
    // 민감도 조정
    const sensitivity = deltaY > 0 ? 10 : 5;
    const newHeight = startHeightRef.current + deltaY / sensitivity;
    setListHeight(Math.min(Math.max(newHeight, 20), 90));
  }, []);

  const handleTouchEndHandle = useCallback(() => {
    isDraggingRef.current = false;
    // 스냅
    setListHeight((prevHeight) => {
      const initialHeight = startHeightRef.current;
      if (prevHeight < initialHeight) {
        // 아래로 드래그 했으면 30vh
        return 30;
      } else {
        // 위로 드래그 했으면 72vh
        return 72;
      }
    });
  }, []);

  // =========================================================================
  // = 카드 스크롤 영역 (스크롤 최상단에서 드래그로 전환)
  // =========================================================================
  const handleTouchStartCards = useCallback((e) => {
    const container = cardsContainerRef.current;
    // 스크롤이 최상단이면 곧장 드래그 시작
    if (container.scrollTop <= 0) {
      startYRef.current = e.touches[0].clientY;
      startHeightRef.current = listHeight;
      isDraggingRef.current = true;
    } else {
      // 스크롤 남아있으면 드래그는 false
      isDraggingRef.current = false;
    }
  }, [listHeight]);

  const handleTouchMoveCards = useCallback((e) => {
    if (isDraggingRef.current) {
      // 이미 드래그 상태 -> 시트 높이 조절
      const currentY = e.touches[0].clientY;
      const deltaY = startYRef.current - currentY;
      const sensitivity = deltaY > 0 ? 10 : 5;
      const newHeight = startHeightRef.current + deltaY / sensitivity;
      setListHeight(Math.min(Math.max(newHeight, 20), 90));

      // 드래그 중에는 스크롤 이벤트 막기
      e.preventDefault();
      return;
    }
    // 아직 드래그 중이 아니면 스크롤 진행
    const container = cardsContainerRef.current;
    const prevScrollTop = container.scrollTop;

    // 다음 프레임에 스크롤 포지션 확인
    setTimeout(() => {
      const newScrollTop = container.scrollTop;
      // 스크롤이 최상단에 도달 -> 드래그 전환
      if (newScrollTop === 0 && prevScrollTop !== 0) {
        isDraggingRef.current = true;
        startYRef.current = e.touches[0].clientY;
        startHeightRef.current = listHeight;
      }
    }, 0);
  }, [listHeight]);

  const handleTouchEndCards = useCallback(() => {
    if (isDraggingRef.current) {
      // 드래그 중이었다면 스냅
      isDraggingRef.current = false;
      setListHeight((prevHeight) => {
        const initialHeight = startHeightRef.current;
        if (prevHeight < initialHeight) {
          // 아래로 드래그 -> 30vh
          return 30;
        } else {
          // 위로 드래그 -> 72vh
          return 72;
        }
      });
    }
  }, []);

  return (
    <div className="search-clubs-page">
      {notificationText && (
        <NotificationPopup
          message={notificationText}
          onClose={() => setNotificationText("")}
        />
      )}

      {/* 상단 검색 바 */}
      <header className="search-header">
        <SearchBar onSearch={handleSearchChange} />
      </header>

      {/* 필터 영역 */}
      <div className="filters-row">
        <div className="area-dropdown">
          <button
            className={`dropdown-btn ${isNearbyActive ? 'active' : ''}`}
            onClick={handleNearbyClick}
          >
            <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: '5px' }} />
            내 주변
          </button>
          <button className="dropdown-btn" onClick={toggleDropdown}>
            {selectedLocation ? `${selectedLocation} ▼` : '지역 선택 ▼'}
          </button>
          <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
            {locations.map((location) => (
              <button
                key={location}
                className={selectedLocation === location ? 'active' : ''}
                onClick={() => handleLocationClick(location)}
              >
                {location}
              </button>
            ))}
          </div>
          <button
            className="dropdown-btn"
            onClick={() => setShowGenrePopup((prev) => !prev)}
          >
            {selectedGenre ? `${selectedGenre} ▼` : '장르별 ▼'}
          </button>
          <div className={`dropdown-menu2 ${showGenrePopup ? 'show' : ''}`}>
            {genres.map((genre) => (
              <button
                key={genre}
                className={selectedGenre === genre ? 'active' : ''}
                onClick={() => handleGenreClick(genre)}
              >
                {genre.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* 클럽 리스트 영역 (드래그 높이 조절) */}
      <div
        className="club-list"
        style={{ height: `${listHeight}vh` }}
      >
        {/* 드래그 핸들 (조건 없이 드래그 가능) */}
        <div
          className="drag-handle"
          onTouchStart={handleTouchStartHandle}
          onTouchMove={handleTouchMoveHandle}
          onTouchEnd={handleTouchEndHandle}
        >
          <div className="drag-handle-bar" />
        </div>

        {/* 스크롤 영역 (스크롤 최상단에서 드래그 전환) */}
        <div
          className="club-cards-container"
          ref={cardsContainerRef}
          onTouchStart={handleTouchStartCards}
          onTouchMove={handleTouchMoveCards}
          onTouchEnd={handleTouchEndCards}
        >
          {isLoading ? (
            <div className="loading-spinner-container">
              <img
                src="/How2Play_mainLogo128.png"
                alt="Loading"
                className="logo-spin"
              />
            </div>
          ) : filteredClubs.length === 0 ? (
            <p className="no-results">검색 결과가 없습니다.</p>
          ) : (
            filteredClubs.map((club) => (
              <ClubCard
                key={club.id}
                club={club}
                isLiked={likedClubs.includes(club.id)}
                onCardClick={() => handleOpenClubDetails(club)}
                onLikeClick={() => handleLikeClick(club)}
              />
            ))
          )}
        </div>
      </div>

      {/* 지도 영역 */}
      <div className="map-container">
        <MapPage
          clubs={filteredClubs}
          selectedLocation={selectedLocation}
          isNearbyActive={isNearbyActive}
          onSelectClub={handleSelectClubFromMap}
        />
      </div>

      {/* 클럽 상세 팝업 */}
      {showClubDetails && selectedClub && (
        <ClubDetailPopup club={selectedClub} onClose={handleClosePopup} />
      )}

      {/* 하단 네비게이션 바 */}
      <Navbar />
    </div>
  );
};

export default SearchClubsPage;
