import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import clubsReducer from './slices/clubsSlice'; // Import clubsReducer


const store = configureStore({
  reducer: {
    auth: authReducer,
    clubs: clubsReducer, // Add clubsReduce
  },
});

export default store;
