import React, { useEffect } from 'react';
import './NotificationPopup.css';

const NotificationPopup = ({ message, duration = 10000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return <div className="notification-popup">{message}</div>;
};

export default NotificationPopup;
