import React, { useEffect, useMemo, useState } from 'react';
import './MyPagev2.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllClubs } from '../../store/slices/clubsSlice';
import MyPage from '../MyPage/MyPage';

const MyPageV2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Call all Hooks first
  const { user, isAuthenticated, isGuest, isLoading } = useSelector(
    (state) => state.auth
  );
  const allClubs = useSelector((state) => state.clubs.allClubs) || [];
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // Close drawer
  const closeSettings = () => setIsSettingsOpen(false);

  useEffect(() => {
    if (!isLoading && allClubs.length === 0) {
      dispatch(fetchAllClubs());
    }
  }, [dispatch, isLoading, allClubs.length]);

  // Visited clubs
  const visitedClubs = user?.visitedClubs || [];

  // Random favorite
  const favoriteClubObj = useMemo(() => {
    if (visitedClubs.length === 0) return null;
    const visitedData = allClubs.filter((club) => visitedClubs.includes(club.id));
    if (visitedData.length === 0) return null;
    const randomIndex = Math.floor(Math.random() * visitedData.length);
    return visitedData[randomIndex];
  }, [allClubs, visitedClubs]);

  // Random recommended
  const recommendedClubObj = useMemo(() => {
    if (visitedClubs.length === 0) return null;
    const notVisitedData = allClubs.filter(
      (club) => !visitedClubs.includes(club.id)
    );
    if (notVisitedData.length > 0) {
      const randomIndex = Math.floor(Math.random() * notVisitedData.length);
      return notVisitedData[randomIndex];
    }
    return allClubs.length > 0
      ? allClubs[Math.floor(Math.random() * allClubs.length)]
      : null;
  }, [allClubs, visitedClubs]);

  // ================================
  // 2) Conditional Logic
  // ================================

  // If loading
  if (isLoading) {
    return <div style={{ color: '#fff' }}>Loading...</div>;
  }

  // If user is neither auth nor guest
  if (!isAuthenticated && !isGuest) {
    return null;
  }

  // Fallback condition
  const showFallback = !isAuthenticated || visitedClubs.length === 0;

  // Prepare variables for main content
  const userName = user?.name || 'OOO';
  const favoriteClub = favoriteClubObj ? favoriteClubObj.name : 'Club XYZ';
  const favoriteGenre =
    favoriteClubObj && favoriteClubObj.genres
      ? favoriteClubObj.genres.split(',')[0].trim()
      : 'HipHop';
  const recommendedClub = recommendedClubObj
    ? recommendedClubObj.name
    : 'Club ABC';

  return (
    <div className="mypagev2">
      {/* -------- Header -------- */}
      <div className="mypagev2-header">
        {/* Only show the gear icon if NOT a guest */}
        {!isGuest && (
          <FontAwesomeIcon
            icon={faCog}
            className="mypagev2-settings-icon"
            onClick={() => setIsSettingsOpen(true)}
          />
        )}
      </div>

      {/* -------- Fallback or Main -------- */}
      {showFallback ? (
        <div className="fallback-content">
          <h2>아직 방문한 클럽이 없어요!</h2>
          <p> 클럽을 방문하여 새로운 경험을 시작해보세요!</p>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
            <button className="fallback-button" onClick={() => navigate('/clubs')}>
              클럽 둘러보기
            </button>
            {(!isAuthenticated || isGuest) && (
              <button
                className="fallback-button"
                onClick={() => navigate('/login')}
              >
                로그인 하러가기
              </button>
            )}
          </div>
        </div>
      ) : (
        <>
          {/* Main Content */}
          <div className="mypagev2-content">
            <div className="mypagev2-image-wrapper">
              <img
                src="https://via.placeholder.com/300x200"
                alt="Club"
                className="mypagev2-image"
              />
            </div>
            <div className="mypagev2-text-info">
              <h2 className="mypagev2-user-name">
                {userName}님께서 가장 많이 방문하신 클럽
              </h2>
              <p className="mypagev2-user-text">
                오늘 현재까지 가장 많이 방문한 클럽은{' '}
                <strong className="highlight">{favoriteClub}</strong> 입니다.
              </p>
              <p className="mypagev2-genre-text">
                {favoriteGenre} 장르의 클럽을 가장 많이 방문하셨습니다.
              </p>
              <p className="mypagev2-club-location">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="mypagev2-location-icon"
                />
                Your Favorite Block: <strong className="highlight">홍대</strong>
              </p>
              <p className="mypagev2-visit-count">
                총 방문 횟수: <span>{visitedClubs.length}</span>
              </p>
            </div>
          </div>

          {/* Recommendation */}
          <div className="mypagev2-recommend-section">
            <h3 className="mypagev2-recommend-title">당신을 위한 다음 추천 클럽</h3>
            <button
              className="mypagev2-recommend-button"
              onClick={() => navigate(`/club/${recommendedClub}`)}
            >
              {recommendedClub} 방문하기
            </button>
          </div>

          {/* Graph */}
          <div className="mypagev2-graph-section">
            <h3 className="mypagev2-graph-title">
              {userName}님께서 주로 방문하시는 지역
            </h3>
            <div className="mypagev2-bar">
              <span className="bar-label">홍대</span>
              <div className="bar-container">
                <div className="bar" style={{ '--bar-width': '80%' }}>
                  <span className="bar-value">80</span>
                </div>
              </div>
            </div>
            <div className="mypagev2-bar">
              <span className="bar-label">이태원</span>
              <div className="bar-container">
                <div className="bar" style={{ '--bar-width': '60%' }}>
                  <span className="bar-value">60</span>
                </div>
              </div>
            </div>
            <div className="mypagev2-bar">
              <span className="bar-label">강남</span>
              <div className="bar-container">
                <div className="bar" style={{ '--bar-width': '40%' }}>
                  <span className="bar-value">40</span>
                </div>
              </div>
            </div>
            <div className="mypagev2-bar">
              <span className="bar-label">신촌</span>
              <div className="bar-container">
                <div className="bar" style={{ '--bar-width': '50%' }}>
                  <span className="bar-value">50</span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* -------- Side Drawer (MyPage) -------- */}
      <div className={`mypagev2-side-drawer ${isSettingsOpen ? 'open' : ''}`}>
        <MyPage onClose={closeSettings} />
      </div>
    </div>
  );
};

export default MyPageV2;
