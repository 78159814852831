import React, { useState } from 'react';
import './FindIdPage.css';
import { useNavigate } from 'react-router-dom';

const FindIdPage = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('email');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const handleTabChange = (newTab) => {
    setTab(newTab);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    navigate('/login');
  };

  return (
    <div className="find-id-page">
      <h1 className="find-id-title">아이디 찾기</h1>
      <p className="find-id-description">
        최초 회원가입 시 등록한 회원정보를 입력하시면 아이디를 찾을 수 있습니다.
      </p>

      <div className="tab-container">
        <button
          className={`tab-btn ${tab === 'email' ? 'active' : ''}`}
          onClick={() => handleTabChange('email')}
        >
          이메일
        </button>
        <button
          className={`tab-btn ${tab === 'phone' ? 'active' : ''}`}
          onClick={() => handleTabChange('phone')}
        >
          휴대폰
        </button>
      </div>

      <form onSubmit={handleSubmit} className="find-id-form">
        <input
          type="text"
          name="name"
          placeholder="이름"
          value={formData.name}
          onChange={handleInputChange}
          className="input-field"
        />
        {tab === 'email' ? (
          <input
            type="text"
            name="email"
            placeholder="이메일"
            value={formData.email}
            onChange={handleInputChange}
            className="input-field"
          />
        ) : (
          <input
            type="text"
            name="phone"
            placeholder="휴대폰 번호 (- 없이 번호만 입력)"
            value={formData.phone}
            onChange={handleInputChange}
            className="input-field"
          />
        )}

        <button type="submit" className="find-id-submit-btn">
          아이디 찾기
        </button>
      </form>
    </div>
  );
};

export default FindIdPage;
